import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectMicareListData, selectMichildcareTabTitles } from '../../state/MasterData';
import { saveProgressLoadingState, selectBaseUrl } from '../../state/UIState';
import { Tab } from 'react-bootstrap';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  MI_CHILD_CARE_TAB_KEYS,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import NavTabs from '../../common/ui/tabs/NavTabs';
import AboutTab from './tabs/AboutTab';
import PolicyTab from './tabs/PolicyTab';
import CostDetailsTab from './tabs/CostDetailsTab';
import RequestsTab from './tabs/RequestsTab';
import { apiRequest } from '../../services/Service';
import { GET_MI_CHILD_CARE } from '../../services/ApiUrls';
import MiCareListItem from './component/MiCareListItem';

const MichildcareMainScreen = ({ history }) => {
  const [data, setData] = useState({});
  const michildcareTabs = useSelector(selectMichildcareTabTitles);
  const anniversaryDayTitles = useSelector(selectMicareListData);
  const [filteredArr, setFilteredArr] = useState(anniversaryDayTitles);
  const [activeTab, setActiveTab] = useState('');
  const dispatch = useDispatch();
  const baseUrl = useSelector(selectBaseUrl);
  const activeRoutes = [];
  for (var val in michildcareTabs) {
    if (michildcareTabs[val].active) {
      activeRoutes.push({
        key: michildcareTabs[val].key,
        title: michildcareTabs[val].title.toUpperCase(),
      });
    }
  }
  const [routes] = useState(activeRoutes);
  useEffect(() => {
    if (routes?.length) {
      setActiveTab(routes?.[0]?.key);
    }
  }, [routes]);

  useEffect(() => {
    // Analytics
    trackScreen(ANALYTICS_SCREEN_NAMES.MI_CHILD_CARE, NAVIGATION_ROUTES.MI_CHILD_CARE);
  }, []);

  const callMiChildCareApi = useCallback(async () => {
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      logEvent(
        ANALYTICS_EVENT_TYPES.MI_CHILD_CARE_GET_DATA,
        '',
        ANALYTICS_ITEM_NAMES.MI_CHILD_CARE
      );
      const apiResponse = await apiRequest(GET_MI_CHILD_CARE, REQUEST_TYPE.GET, {});
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response) {
            const data = apiResponse?.response?.data?.feeds || {};
            setData(data);
          }
        }
      }
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    } catch (err) {
      // handleError(err, {}, ARTICLE_BY_ID + articleId, NAVIGATION_ROUTES.MICROBIZ_DETAIL);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch]);

  useEffect(() => {
    callMiChildCareApi();
  }, [callMiChildCareApi]);

  const renderTabs = (tab) => {
    switch (tab?.key) {
      case MI_CHILD_CARE_TAB_KEYS.ABOUT:
        return <AboutTab data={data?.about} />;
      case MI_CHILD_CARE_TAB_KEYS.POLICY:
        return <PolicyTab data={data?.policy} />;
      case MI_CHILD_CARE_TAB_KEYS.COST_DETAILS:
        return <CostDetailsTab data={data?.cost_details} />;
      case MI_CHILD_CARE_TAB_KEYS.REQUESTS:
        return <RequestsTab data={data?.requests} callMiChildCareApi={callMiChildCareApi} />;
      default:
        null;
    }
  };

  const onPressItem = (item) => {
    if (item.identifier === '_childCare') {
      history.push(NAVIGATION_ROUTES.MI_CHILD_CARE_CONTENT);
    }
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MI_CHILD_CARE} />
      {/* {baseUrl !== '' && (
        <NavTabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
          {routes &&
            routes?.map((tab, index) => {
              return (
                <Tab
                  key={`${index}`}
                  eventKey={tab?.key}
                  title={tab?.title}
                  className="main-content-container">
                  {renderTabs(tab, index)}
                </Tab>
              );
            })}
        </NavTabs>
      )} */}
      <div className="main-content-container">
        {filteredArr.map((item, index) => {
          return <MiCareListItem onPressItem={onPressItem} item={item} key={`${index}`} />;
        })}
      </div>
    </div>
  );
};

export default MichildcareMainScreen;
