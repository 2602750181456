import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectAccessibilityData } from '../../../../state/AccessibilityData';
import { getFeatureComponent } from '../../../../utils/accessibility';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  NAVIGATION_ROUTES,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../../common/constants/AppConstants';
import { getUrlWithSpecificRendition } from '../../../../utils/common';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { saveAnniversaryGeoData } from '../../../../state/TabData';

const HomeAnniversaryWidget = ({
  key,
  geography,
  identifier,
  itemImageBaseUrl,
  itemImagePath,
  location,
  title,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <div
      className="ml-35-dashboard-item common-unstyled-achor"
      onClick={() => {
        dispatch(saveAnniversaryGeoData(geography));
        history.push(`${NAVIGATION_ROUTES.ANNIVERSARY_SCREEN}/${identifier}`, {
          tabKey: 'celebrations',
          identifier: identifier,
          geo: geography,
        });
      }}>
      <div className="pt-3 home-classic-text-container">
        <img
          className="embed-responsive embed-responsive-16by9 mb-3"
          src={getUrlWithSpecificRendition(
            itemImageBaseUrl,
            SCREEN_DENSITY_QUALIFIERS.HDPI,
            itemImagePath
          )}
        />
        <div className="home-anniversary-widget-title">
          {geography?.length > 70 ? `${geography?.substring(0, 70)}...` : geography}
        </div>
      </div>
    </div>
  );
};

export default HomeAnniversaryWidget;
