import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  NUMBER_SYMBOLS_LOOKUP,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
} from '../common/constants/AppConstants';
import { getConfigurations, getProfanityList } from './ReduxState';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import Filter from 'bad-words';
import { apiRequest } from '../services/Service';
import { UPLOAD_BASE64_WALLPAPERS } from '../services/ApiUrls';

/* Date and time utils */

export const formatTimeAMPM = (input = '', inputType = 'date') => {
  let inputValue = input;
  if (inputType === 'date') {
    const convertedTimestamp = input?.replace(/-/g, '/');
    const convertedUtcTime = dayjs.utc(convertedTimestamp).subtract(330, 'minute');
    const convertedLocalTime = convertedUtcTime.local();
    const convertedLocalFomattedTime = convertedLocalTime.format('HH:mm:ss');
    inputValue = convertedLocalFomattedTime;
  }
  let hours = Number(inputValue.slice(0, 2));
  let minutes = Number(inputValue.slice(3, 5));
  let ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  let strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
};

export const formatHHMMSSTimeAMPM = (time = '') => {
  const formattedTime = dayjs(time, 'HH:mm:ss').format('hh:mm A');
  return formattedTime;
};

export const formatDateDDthMonth = (dateString) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const date = new Date(dateString);
  const day = date.getDate();
  const month = months[date.getMonth()];

  let dayFormatted;

  if (day === 1 || day === 21 || day === 31) {
    dayFormatted = `${day}st`;
  } else if (day === 2 || day === 22) {
    dayFormatted = `${day}nd`;
  } else if (day === 3 || day === 23) {
    dayFormatted = `${day}rd`;
  } else {
    dayFormatted = `${day}th`;
  }

  return `${dayFormatted} ${month}`;
};

export const formatDateDDthMonthYYYY = (dateString) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const date = new Date(dateString);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  let dayFormatted;

  if (day === 1 || day === 21 || day === 31) {
    dayFormatted = `${day}st`;
  } else if (day === 2 || day === 22) {
    dayFormatted = `${day}nd`;
  } else if (day === 3 || day === 23) {
    dayFormatted = `${day}rd`;
  } else {
    dayFormatted = `${day}th`;
  }

  return `${dayFormatted} ${month} ${year.toFixed()}`;
};

export const formatDateDDMonth = (date = '') => {
  const convertedDate = date.replace(/-/g, '/');
  const formattedDate = dayjs(convertedDate).format('DD MMM');
  return formattedDate;
};

export const formatDateDoMMM = (date = '') => {
  const convertedDate = date.replace(/-/g, '/');
  const date2 = new Date(convertedDate);
  let txt =
    date2.getDate() === 1 || date2.getDate() === 21 || date2.getDate() === 31
      ? 'st'
      : date2.getDate() === 2 || date2.getDate() === 22
      ? 'nd'
      : date2.getDate() === 3 || date2.getDate() === 23
      ? 'rd'
      : 'th';
  const formattedDate = date2.getDate() + `${txt} ` + dayjs(convertedDate).format('MMM');
  return formattedDate;
};

export const formatDateDDMonthYYYY = (date = '', isFromUtcToLocal = true) => {
  const convertedTimestamp = date?.replace(/-/g, '/');
  let convertedLocalTime;
  if (isFromUtcToLocal) {
    const convertedUtcTime = dayjs.utc(convertedTimestamp).subtract(330, 'minute');
    convertedLocalTime = convertedUtcTime.local();
  } else {
    convertedLocalTime = dayjs.utc(convertedTimestamp);
  }
  const convertedLocalFomattedTime = convertedLocalTime.format('DD MMM YYYY');
  return convertedLocalFomattedTime;
};

export const formatDateDDMonthYYYYEStore = (date = '', isFromUtcToLocal = true) => {
  const convertedTimestamp = date?.replace(/-/g, '/');
  let convertedLocalTime;
  if (isFromUtcToLocal) {
    const convertedUtcTime = dayjs.utc(convertedTimestamp).subtract(330, 'minute');
    convertedLocalTime = convertedUtcTime.local();
  } else {
    convertedLocalTime = dayjs.utc(convertedTimestamp);
  }
  const convertedLocalFomattedTime = convertedLocalTime.format('DD-MMM-YYYY');
  return convertedLocalFomattedTime;
};

export const formatDateDDMonthYYYYAndAdd1Day = (date = '', isFromUtcToLocal = true) => {
  const convertedTimestamp = dayjs(date.replace(/-/g, '/')).add(1, 'day');
  let convertedLocalTime;
  if (isFromUtcToLocal) {
    const convertedUtcTime = dayjs.utc(convertedTimestamp).subtract(330, 'minute');
    convertedLocalTime = convertedUtcTime.local();
  } else {
    convertedLocalTime = dayjs.utc(convertedTimestamp);
  }
  const convertedLocalFomattedTime = convertedLocalTime.format('DD MMM YYYY');
  return convertedLocalFomattedTime;
};

export const formatDateDDMonthYYYYTimeAMPM = (date = '') => {
  const formattedDate = formatDateDDMonthYYYY(date);
  const formattedTime = formatTimeAMPM(date);
  const formattedDateTime = `${formattedDate} ${formattedTime}`;
  return formattedDateTime;
};

export const formatDateWDDMM = (date = '') => {
  const convertedDate = date.replace(/-/g, '/');
  const formattedDate = dayjs(convertedDate).format('ddd, DD MMM');
  return formattedDate;
};

export const getDateInYYYYMMDD = (date) => {
  const formattedDate = dayjs(date).format('YYYY-MM-DD');
  return formattedDate;
};

export const getDateInMMMYYYY = (date) => {
  const formattedDate = dayjs(date).format('MMM YYYY');
  return formattedDate;
};

export const getDateInMMMMYYYY = (date) => {
  const formattedDate = dayjs(date).format('MMMM, YYYY');
  return formattedDate;
};

export const getDateInMMMDDYYYY = (date = '') => {
  const convertedDate = date.replace(/-/g, '/');
  const formattedDate = dayjs(convertedDate).format('MMMM DD, YYYY');
  return formattedDate;
};

export const getDateInDDMMMYYYY = (date = '') => {
  if (date) {
    const convertedDate = date.replace(/-/g, '/');
    const formattedDate = dayjs(convertedDate).format('DD MMM, YYYY');
    return formattedDate;
  } else {
    return '';
  }
};

export const getFormattedDate = (date = '', format = 'MMMM DD, YYYY') => {
  if (!date) {
    return '';
  }
  const convertedDate = date.replace(/-/g, '/');
  const formattedDate = dayjs(convertedDate).format(format);
  return formattedDate;
};

export const getTomorrowDateInYYYYMMDD = () => {
  const formattedDate = dayjs().add(1, 'day').format('YYYY-MM-DD');
  return formattedDate;
};

export const getFutureYearDateInYYYYMMDD = (year = 1) => {
  const formattedDate = dayjs().add(year, 'year').format('YYYY-MM-DD');
  return formattedDate;
};

export const isToday = (inputTimestamp = '') => {
  const convertedTimestamp = inputTimestamp.replace(/-/g, '/');
  const convertedUtcTime = dayjs.utc(convertedTimestamp).subtract(330, 'minute');
  const convertedLocalTime = convertedUtcTime.local();
  const convertedLocalTimeDate =
    convertedLocalTime.get('year').toString() +
    '/' +
    convertedLocalTime.get('month').toString() +
    '/' +
    convertedLocalTime.get('date').toString();
  const todayDate =
    dayjs().get('year').toString() +
    '/' +
    dayjs().get('month').toString() +
    '/' +
    dayjs().get('date').toString();
  if (convertedLocalTimeDate === todayDate) {
    return true;
  } else {
    return false;
  }
};

export const isEqualDates = (firstDate, secondDate) => {
  return firstDate.split(' ')[0] === secondDate.split(' ')[0];
};

export const formatStartAndEndDate = (startDate, endDate) => {
  if (isEqualDates(startDate, endDate)) {
    const eventDate = `${formatDateDDMonthYYYY(startDate)} | ${formatTimeAMPM(
      startDate
    )} - ${formatTimeAMPM(endDate)}`;
    return eventDate;
  } else {
    const eventDate = `${formatDateDDMonthYYYYTimeAMPM(
      startDate
    )} - ${formatDateDDMonthYYYYTimeAMPM(endDate)}`;
    return eventDate;
  }
};

export const shortYearDate = (date = '') => {
  const convertedDate = date.replace(/-/g, '/');
  const formattedDate = dayjs(convertedDate).format('YY');
  return formattedDate;
};

export const formatTimeHrsMins = (hrs, min) => {
  let value = '';
  if (hrs === 0 && min === 0) {
    value = '0 hrs 0 mins';
  } else if (hrs === 0) {
    value = `${min} mins`;
  } else if (min === 0) {
    value = `${hrs} hrs`;
  } else {
    value = `${hrs} hrs ${min} mins`;
  }
  return value;
};

export const formatTimeHrsMinsVolunteer = (hrs, min) => {
  let value = '';
  if (hrs === 0 && min === 0) {
    value = '0 hrs';
  } else if (hrs === 0) {
    value = `0:${min} hrs`;
  } else if (min === 0) {
    if (hrs === 1) {
      value = `${hrs} hr`;
    } else {
      value = `${hrs} hrs`;
    }
  } else {
    value = `${hrs}:${min} hrs`;
  }
  return value;
};

export const formatUtcTimestampToLocalDateDDMonthYYYYTimeAMPM = (timestamp = '') => {
  const convertedTimestamp = timestamp.replace(/-/g, '/');
  const localTimestamp = dayjs.utc(convertedTimestamp).local();
  const formattedTimestamp = localTimestamp.format('DD MMM YYYY h:mm A');
  return formattedTimestamp;
};

export const getCurrentYear = () => {
  const currentYear = dayjs().get('year').toString();
  return currentYear;
};

export const formatRelativeTimeFromNow = (date) => {
  const convertedTimestamp = date.replace(/-/g, '/');
  const convertedUtcTime = dayjs.utc(convertedTimestamp).subtract(330, 'minute');
  const convertedLocalTime = convertedUtcTime.local();
  return convertedLocalTime.fromNow();
};

export const formatSecondsIntoMinutes = (seconds) => {
  if (Number(seconds) < 60) {
    return `${Math.ceil(seconds)} sec read`;
  } else {
    let minutes = Math.floor(seconds / 60);
    return `${Math.ceil(minutes)} min read`;
  }
};

export const formatSecondsIntoTimeForVideos = (sec) => {
  if (!isNaN(sec)) {
    var sec_num = parseInt(sec, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    if (parseInt(hours) == 0 && parseInt(minutes) == 0) {
      return parseInt(seconds) + ' sec';
    } else if (parseInt(hours) == 0 && parseInt(minutes) != 0) {
      return minutes + ':' + seconds + ' min';
    } else {
      return hours + ':' + minutes + ':' + seconds + ' hr';
    }
  } else {
    return null;
  }
};

export const formatSecondsToHHMMSS = (timer, type) => {
  var hours = Math.floor((timer % (3600 * 24)) / 3600);
  var minutes = Math.floor((timer % 3600) / 60);
  var seconds = Math.floor(timer % 60);
  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;
  if (hours === '00') {
    if (type === 1) {
      return minutes + ':' + seconds;
    } else if (type === 2) {
      return minutes + ' min ' + seconds + ' sec';
    }
  } else {
    if (type === 1) {
      return hours + ':' + minutes + ':' + seconds;
    } else if (type === 2) {
      return hours + ' hrs ' + minutes + ' min ' + seconds + ' sec';
    }
  }
};

export const isLeapYear = (year) => {
  return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
};

/* Miscellaneous utils */

export const showToast = (message) => {
  toast.dark(message);
};

export const sortBySequence = (data) => {
  return data
    ?.slice()
    ?.sort((a, b) =>
      Number(a.sequence) > Number(b.sequence) ? 1 : Number(b.sequence) > Number(a.sequence) ? -1 : 0
    );
};

export const sortArrayOfObjects = (data = [], attribute) => {
  return data.slice().sort((a, b) => a[attribute].localeCompare(b[attribute]));
};

export const getDeviceScreenDensityQualifier = () => {
  return SCREEN_DENSITY_QUALIFIERS.XXHDPI;
};

export const formatUrlWithDeviceScreenDensityQualifierXxxHdpi = (url) => {
  let transformedUrl = url.split('/');
  transformedUrl.splice(-1, 0, SCREEN_DENSITY_QUALIFIERS.XXXHDPI);
  transformedUrl = transformedUrl.join('/');
  return transformedUrl;
};

export const getDeviceScreenDensityQualifierAsPath = () => {
  const deviceScreenDensityQualifier = getDeviceScreenDensityQualifier();
  const path = deviceScreenDensityQualifier + '/';
  return path;
};

export const getUrlWithSpecificRendition = (part1, rendition, part2) => {
  switch (rendition) {
    case SCREEN_DENSITY_QUALIFIERS.LDPI:
      return part1 + `${SCREEN_DENSITY_QUALIFIERS.LDPI}/` + part2;
    case SCREEN_DENSITY_QUALIFIERS.MDPI:
      return part1 + `${SCREEN_DENSITY_QUALIFIERS.MDPI}/` + part2;
    case SCREEN_DENSITY_QUALIFIERS.HDPI:
      return part1 + `${SCREEN_DENSITY_QUALIFIERS.HDPI}/` + part2;
    case SCREEN_DENSITY_QUALIFIERS.XHDPI:
      return part1 + `${SCREEN_DENSITY_QUALIFIERS.XHDPI}/` + part2;
    case SCREEN_DENSITY_QUALIFIERS.XXHDPI:
      return part1 + `${SCREEN_DENSITY_QUALIFIERS.XXHDPI}/` + part2;
    case SCREEN_DENSITY_QUALIFIERS.XXXHDPI:
      return part1 + `${SCREEN_DENSITY_QUALIFIERS.XXXHDPI}/` + part2;
    default:
      return '';
  }
};

export const trimTrailingCommasAndWhitespaces = (string) => {
  return string.replace(/(^[,\s]+)|([,\s]+$)/g, '');
};

export const formatUrlWithDeviceScreenDensityQualifier = (url) => {
  let transformedUrl = url.split('/');
  transformedUrl.splice(-1, 0, getDeviceScreenDensityQualifier());
  transformedUrl = transformedUrl.join('/');
  return transformedUrl;
};

export const formatUrlWithDeviceScreenDensityQualifierLdpi = (url) => {
  let transformedUrl = url.split('/');
  transformedUrl.splice(-1, 0, SCREEN_DENSITY_QUALIFIERS.LDPI);
  transformedUrl = transformedUrl.join('/');
  return transformedUrl;
};

export const formatPathWithBaseUrl = (path) => {
  return Object.keys(getConfigurations()).length > 0
    ? getConfigurations()._accessibility_base_url.toString() + path
    : '';
};

export const checkIsValidYoutubeURL = (url) => {
  if (!url) {
    return false;
  }
  let youtubeRegex = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/i;
  return youtubeRegex.test(url);
};

export const getYoutubeIdFromUrl = (url) => {
  var ID = '';
  let youtubeURL = url;
  if (url.includes('https://youtube.com/shorts/')) {
    if (url.includes('?')) {
      youtubeURL = youtubeURL.split('?')[0];
    }
    return youtubeURL.replace('https://youtube.com/shorts/', '');
  }
  if (url.includes('https://www.youtube.com/shorts/')) {
    if (url.includes('?')) {
      youtubeURL = youtubeURL.split('?')[0];
    }
    return youtubeURL.replace('https://www.youtube.com/shorts/', '');
  }
  if (url.includes('https://www.youtube.com/live/')) {
    if (url.includes('?')) {
      youtubeURL = youtubeURL.split('?')[0];
    }
    return youtubeURL.replace('https://www.youtube.com/live/', '');
  }
  url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (url[2] !== undefined) {
    ID = url[2].split(/[^0-9a-z_/-]/i);
    ID = ID[0];
  } else {
    ID = url;
  }
  return ID;
};

export const getYoutubeIdFromUrlParameters = (url) => {
  return url.split('&')[0];
};

export const replaceUnicodeSetWithBullet = (string) => {
  return string.replace(/â€¢/g, '•');
};

export const replaceMultipleNewLineWithSpace = (text) => {
  return text.replace(/[\r\n]+/g, ' ');
};

export const getAwardIcon = (iconName) => {
  switch (iconName) {
    case 'bestinclass':
      return ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_CREAM_OF_TEAM;
    case 'innovativehat':
      return ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_TECH_WIZARDS;
    case 'techwizard':
      return ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_TECK_GHEEK;
    case 'customerschoice':
      return ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_CUSTOMER_CHOICE;
    case 'supersquad':
      return ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_SUPER_SQUAD;
    case 'superskipper':
      return ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_SUPER_SKIPPER;
    case 'brightbeginner':
      return ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_BRIGHT_BEGINNER;
    case 'wow':
      return ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_WOW;
    case 'applause':
      return ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_APPLAUSE;
    case 'keycontributor':
      return ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_KEY_CONTRIBUTOR;
    case 'halloffame':
      return ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_HALL_OF_FAME;
    case 'ceoschoice':
      return ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_CEO_CHOICE;
    case 'servicebadge':
      return ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_DEFAULT_BADGE;
    case 'dependable':
      return ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_DEPENDABLE_BADGE;
    case 'innovator':
      return ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_INNOVATOR_BADGE;
    case 'isrchamp':
      return ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_ISRCHAMP_BADGE;
    case 'isrchampionstrophy':
      return ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_ISRCHAMPIONSTROPHY_BADGE;
    case 'problemsolver':
      return ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_PROBLEMSOLVER_BADGE;
    case 'speedyfinisher':
      return ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_SPEEDYFINISHER_BADGE;
    case 'teamplayer':
      return ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_TEAMPLAYER_BADGE;
    default:
      return ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_DEFAULT_BADGE;
  }
};

export const replaceBrTag = (string) => {
  const newString = string.replace(/<br\s*\\?\/>/g, '\r\n');
  return newString;
};
export const replaceNonAsciiCharsWithSpace = (string) => {
  return string.replace(/[^\x00-\x7F]/g, '');
};

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const replaceWhitespacesWithKey = (str, key) => {
  return str.replace(/ /g, key);
};

export const downloadImage = (baseUrl, imagePath) => {
  var a = document.createElement('a');
  a.href = baseUrl + getDeviceScreenDensityQualifierAsPath() + imagePath;
  a.download = imagePath;
  a.click();
};

export const removeHtmlContent = (str = '') => {
  return str.replace(/<[^>]*>?/gm, '');
};

export const formatNumberToClosestAbbreviation = (num, digits) => {
  var item = NUMBER_SYMBOLS_LOOKUP.slice()
    .reverse()
    .find(function (val) {
      return num >= val.value;
    });
  return item
    ? num % item.value === 0 || (num / item.value).toString().split('.')[1].length === 1
      ? num / item.value + item.symbol
      : (num / item.value).toFixed(digits) + '+' + item.symbol
    : '0';
};

export const isEmptyText = (string) => {
  const regex = /[\u200b-\u200d \uFEFF]/gu;
  return string.replace(regex, '').trim();
};

export const getProfanityFilter = () => {
  let filter = new Filter();
  filter.addWords(...getProfanityList());
  return filter;
};

export const getYoutubeVideoImageFromUrl = (url) => {
  if (url) {
    const imageUrl = `https://img.youtube.com/vi/${getYoutubeIdFromUrl(url)}/maxresdefault.jpg`;
    return imageUrl;
  }
};

export const shareImage = (platform, url) => {
  const baseURL =
    platform === 'Linkedin'
      ? 'https://www.linkedin.com/sharing/share-offsite/?url='
      : platform === 'Facebook'
      ? 'http://www.facebook.com/sharer.php?u='
      : 'https://twitter.com/intent/tweet?url=';
  window.open(
    baseURL + encodeURIComponent(url),
    'sharer',
    'toolbar=0,status=0,width=650,height=550'
  );
};

export const shareImageBase64 = async (platform, base64) => {
  const formData = new URLSearchParams();
  formData.append('file', base64);
  formData.append('feature', 'personalisedwallpaper');
  formData.append('renditions', false);

  try {
    const response = await apiRequest(UPLOAD_BASE64_WALLPAPERS, REQUEST_TYPE.POST, formData);
    if (Object.keys(response.response)) {
      const image = response.response?.data;
      shareImage(platform, image.base_url + image.image_path);
    }
  } catch (e) {
    console.log(e);
    showToast('Something went wrong');
  }
};

export const getSecuredPdfUrl = (webUrl, encodedUrl) => {
  // tenmporay change
  // const baseUrl = getBaseUrl();
  const baseUrl = '';
  return `${webUrl}loadpdf?endpoint=${baseUrl}fetch_pdf?encoded_url=${encodedUrl}%26type=inline`;
};

export const getSecuredPdfUrlDownload = (webUrl, encodedUrl) => {
  // tenmporay change
  // const baseUrl = getBaseUrl();
  const baseUrl = '';
  return `${webUrl}loadpdf?endpoint=${baseUrl}fetch_pdf?encoded_url=${encodedUrl}%26type=attachment`;
};

export const getSecuredPdfUrlForID = (webUrl) => {
  // tenmporay change
  // const baseUrl = getBaseUrl();
  const baseUrl = '';
  return `${webUrl}loadpdf?endpoint=${baseUrl}downloadidcard`;
};

export const getDevicePixelRatio = () => {
  return window?.devicePixelRatio ?? 1;
};

export const getRandomFileName = () => {
  var timestamp = new Date().toISOString().replace(/[-:.]/g, '');
  var random = ('' + Math.random()).substring(2, 8);
  var random_number = timestamp + random;
  return random_number;
};

export const generatev4UniqueID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const getDDMonthYYYYdddd = (date = '' + new Date(), format = 'DD MMMM YYYY | dddd') => {
  if (!date) {
    return '';
  }
  const formattedDate = dayjs(date).format(format);
  return formattedDate;
};

export const getddddDDMonthYYYYhhmmA = (
  date = '' + new Date(),
  format = 'dddd, DD MMMM YYYY | hh:mm A'
) => {
  if (!date) {
    return '';
  }
  const formattedDate = dayjs(date).format(format);
  return formattedDate;
};

export const getOrderId = (id) => {
  const idStr = `${id}`;
  const orderIdPattern = '#000000000';
  return orderIdPattern.substring(0, orderIdPattern.length - idStr.length).concat(idStr);
};
