import React, { memo, useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { apiRequest, handleError } from '../../../services/Service';
import { GET_TRAVELLER_LIST } from '../../../services/ApiUrls';
import { NAVIGATION_ROUTES, REQUEST_TYPE } from '../../../common/constants/AppConstants';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import { showToast } from '../../../utils/common';

const CreateChooseTravellerDialog = (props) => {
  const {
    isCreateTravellerModalVisible,
    onCloseCreateChooseDialog,
    onPressCreateTraveller,
    travellersFieldArrayProps,
    ticketIndex,
    accommodationIndex,
    fromTab,
  } = props;
  const [travellerList, setTravellerList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const callEmployeeProjectsApi = useCallback(async () => {
    try {
      setIsLoading(true);
      const apiResponse = await apiRequest(GET_TRAVELLER_LIST, REQUEST_TYPE.GET, {});
      setIsLoading(false);
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.travellers.length > 0) {
            setTravellerList(apiResponse.response.travellers);
          }
        }
      }
    } catch (err) {
      handleError(err, {}, GET_TRAVELLER_LIST, NAVIGATION_ROUTES.TRAVEL_REQUEST);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    callEmployeeProjectsApi();
  }, [callEmployeeProjectsApi]);

  const renderItem = (traveller, index) => {
    return (
      <div
        className="col-md-4 col-sm-6 mt-3"
        key={`traveller_${index}`}
        onClick={() => {
          const exists_accomodation =
            travellersFieldArrayProps?.form?.values?.accommodations[accommodationIndex]
              ?.travellers &&
            travellersFieldArrayProps?.form?.values?.accommodations[
              accommodationIndex
            ]?.travellers?.some(
              (existingTraveller) => existingTraveller.traveller_id == traveller.traveller_id
            );
          const exists =
            travellersFieldArrayProps?.form?.values?.tickets[ticketIndex]?.travellers &&
            travellersFieldArrayProps?.form?.values?.tickets[ticketIndex]?.travellers?.some(
              (existingTraveller) => existingTraveller.traveller_id == traveller.traveller_id
            );

          if (fromTab === 'accommodations' && exists_accomodation) {
            showToast('Guest already exists');
          } else if (exists) {
            showToast('Traveller already exists');
          } else {
            travellersFieldArrayProps.push(traveller);
            onCloseCreateChooseDialog();
          }
        }}>
        <div className="d-flex align-items-center common-cursor-pointer travel-request-choose-profile px-2">
          <UserProfilePhoto
            imageBaseUrl={traveller.profile_picture.base_url}
            imagePath={traveller.profile_picture.image_path}
            imageClass="common-user-profile-photo-sm"
            employeeId={traveller.employee_id}
          />
          <div className="ml-3 d-flex align-items-center">
            <div>
              <div>{`${traveller.first_name} ${traveller.last_name}`}</div>
              {traveller.location.title !== '' && <div>{traveller.location.title}</div>}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      onHide={() => onCloseCreateChooseDialog()}
      show={isCreateTravellerModalVisible}
      centered
      size="lg"
      keyboard={false}>
      <Modal.Header className="d-flex align-items-center py-3" closeButton>
        <Modal.Title>Create/choose Traveller</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <div>
          <div
            className="d-flex align-items-center justify-content-center travel-request-add-traveller-view py-2 common-cursor-pointer ml-4 mr-4"
            onClick={() => onPressCreateTraveller()}>
            <div className="travel-request-add-traveller">CREATE TRAVELLER</div>
          </div>
          <div className="mt-3 text-center">or choose</div>
          <div className="d-flex justify-content-center">
            <ActivityLoader visible={isLoading} />
          </div>
          <div className="d-flex flex-wrap">
            {travellerList.map((element, index) => renderItem(element, index))}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default memo(CreateChooseTravellerDialog);
