import React, { useCallback, useState, useEffect } from 'react';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import { saveProgressLoadingState } from '../../../state/UIState';
import { apiRequest } from '../../../services/Service';
import {
  CHILD_CARE_APPLY,
  CHILD_CARE_WITHDRAW,
  GET_MI_CHILD_CARE_CENTRES,
} from '../../../services/ApiUrls';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDateInMMMMYYYY,
  getDateInMMMYYYY,
  getDateInYYYYMMDD,
  getDateInMMMDDYYYY,
  showToast,
  getDateInDDMMMYYYY,
} from '../../../utils/common';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import { selectMiCareConfig } from '../../../state/MasterData';
import { selectEmployeeData } from '../../../state/EmployeeData';
import CustomTextInput from '../../../common/ui/custom_text_input/CustomTextInput';
import { selectAccessData } from '../../../state/UserAccessData';

export default function RequestsTab({ data, callMiChildCareApi, validationData }) {
  const [showDayCareRequestDialogue, setShowDayCareRequestDialogue] = useState(false);
  const [showDayCareRequestSuccessDialogue, setShowDayCareRequestSuccessDialogue] = useState(false);
  const [showWithdrawConfirmation, setShowWithdrawConfirmation] = useState(false);
  const [showAcknowladgment, setShowAcknowladgment] = useState(false);
  const dispatch = useDispatch();
  const [centres, setCentres] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);

  const [childAgeYear, setChildageYear] = useState(null);
  const [childAgeMonth, setChildAgeMonth] = useState(null);
  const employeeData = useSelector(selectEmployeeData);
  const accessData = useSelector(selectAccessData);
  const [showRegisterTab, SetShowRegisterTab] = useState(false);
  const [showRegisterButton, SetShowRegisterButtton] = useState(false);
  const [showWaring, setWarning] = useState(false);
  const [confirmation, SetConfirmationModel] = useState(false);

  const [errorMsg, setErrormsg] = useState('');
  const [showErrorModel, setErrorModel] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [centerName, setCenterName] = useState('');
  const [city, setCity] = useState('');
  const [pincode, setPincode] = useState(null);

  const micareAccessData = accessData?.data?.find((item) => item.feature_key === '_michildcare');
  const [dob, setDob] = useState('');

  const [age, setAge] = useState('Auto Calculated');
  // child name
  const [childname, setChildName] = useState('');
  const [warningmsg, setWarningmsg] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [showAge, setShowAge] = useState(false);

  // start Month
  const [selectedStartMonth, setSelectedStartMonth] = useState(null);

  // end month
  const [selectedEndMonth, setSelectedEndMonth] = useState(null);
  const [endDate, setEndDate] = useState('');
  const acknowledgementPeragraphs = useSelector(selectMiCareConfig);

  // clay centre
  const [selectedClayCentre, setSelectedClayCentre] = useState(null);

  useEffect(() => {
    if (micareAccessData) {
      const actions = micareAccessData?.actions;
      SetShowRegisterTab(actions?.includes('_requests_tab'));
      SetShowRegisterButtton(actions?.includes('_register'));
    }
  }, [micareAccessData]);

  useEffect(() => {
    if (showDayCareRequestDialogue) {
      setDob('');
      setYear(0);
      setMonth(0);
      setWarning(false);
      setWarningmsg('');
      setSelectedClayCentre(null);
      setErrorModel(false);
      setDisableButton(false);
      setCenterName('');
      setCity('');
      setChildName('');
      setPincode(null);
    }
  }, [showDayCareRequestDialogue]);

  // Request response
  const [requestResp, setRequestResp] = useState(null);

  const [ageYears] = useState([
    { label: 0, value: 0 },
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
  ]);

  const [ageMonths] = useState([
    { label: 0, value: 0 },
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
    { label: 7, value: 7 },
    { label: 8, value: 8 },
    { label: 9, value: 9 },
    { label: 10, value: 10 },
    { label: 11, value: 11 },
  ]);

  const MonthNames = {
    0: 'JAN',
    1: 'FEB',
    2: 'MAR',
    3: 'APR',
    4: 'MAY',
    5: 'JUN',
    6: 'JUL',
    7: 'AUG',
    8: 'SEP',
    9: 'OCT',
    10: 'NOV',
    11: 'DEC',
  };

  const generateNext12Months = () => {
    const today = new Date();
    const months = [];

    for (let i = 0; i < 12; i++) {
      const month = (today.getMonth() + i) % 12;
      const year = today.getFullYear() + Math.floor((today.getMonth() + i) / 12);
      const monthName = `${MonthNames[month]} ${year}`;
      months.push({ label: monthName, value: monthName });
    }

    return months;
  };

  const [upcomingMonthsArray] = useState(generateNext12Months());
  const tableColumnHeadings = [
    'Name of child',
    'Date Of Birth',
    'Start Date',
    'End Date',
    'Preferred Klay Centre',
    'Applied on',
    'Status',
    'Actions',
  ];

  function getFirstDayOfMonth(monthYear) {
    // Split the input string into month and year
    const [month, year] = monthYear.toUpperCase().split(' ');

    // Convert month name to its numeric representation
    const monthMap = {
      JAN: 0,
      FEB: 1,
      MAR: 2,
      APR: 3,
      MAY: 4,
      JUN: 5,
      JUL: 6,
      AUG: 7,
      SEP: 8,
      OCT: 9,
      NOV: 10,
      DEC: 11,
    };

    // Get the numeric representation of the month
    const monthIndex = monthMap[month];

    // Construct a Date object with the first day of the specified month and year
    const firstDayOfMonth = new Date(year, monthIndex, 1);

    // Format the date as YYYY-MM-DD
    const formattedDate = `${firstDayOfMonth.getFullYear()}-${(firstDayOfMonth.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-01`;

    return formattedDate;
  }

  useEffect(() => {
    if (selectedStartMonth) {
      const date = getFirstDayOfMonth(selectedStartMonth?.value);
      const dd = new Date(date);
      const newDate = new Date(dd.setMonth(dd.getMonth() + 3));
      const lastDay = new Date(newDate.getFullYear(), newDate.getMonth(), 0);
      let lastDateFormatted = getDateInYYYYMMDD(lastDay);
      setEndDate(lastDateFormatted);

      // Format the date as MMM YYYY e.g. MAY 2024
      const formattedDate = getDateInMMMYYYY(lastDay).toUpperCase();
      let newDateItem = { label: formattedDate, value: formattedDate };
      setSelectedEndMonth(newDateItem);
    }
  }, [selectedStartMonth]);

  const callCentresListApi = useCallback(async () => {
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      logEvent(
        ANALYTICS_EVENT_TYPES.MI_CHILD_CARE_FETCH_CENTRES,
        '',
        ANALYTICS_ITEM_NAMES.MI_CHILD_CARE
      );
      const apiResponse = await apiRequest(GET_MI_CHILD_CARE_CENTRES, REQUEST_TYPE.GET, {});
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response) {
            const data = apiResponse?.response?.data?.feeds || [];
            let list = data.map((x) => {
              let newObj = { ...x, label: x?.name, value: x?.center_id };
              return newObj;
            });
            setCentres(list);
          }
        }
      }
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    } catch (err) {
      // handleError(err, {}, ARTICLE_BY_ID + articleId, NAVIGATION_ROUTES.MICROBIZ_DETAIL);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch]);

  function convertMonthsToYearsAndMonths(totalMonths) {
    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;

    return { years, months };
  }

  const callRegisterApi = useCallback(async () => {
    let acknowledgementText = '';
    acknowledgementPeragraphs.map((peragraph) => (acknowledgementText += `${peragraph}\n`));
    acknowledgementText += `Employee Signature: ${employeeData.first_name} ${employeeData.middle_name} ${employeeData.last_name}`;

    const ageInMonths = (calculateAge(dob)?.year || 0) * 12 + (calculateAge(dob)?.month || 0);

    if (childname === '' || selectedClayCentre === null || dob === '') {
      if (childname === '' && selectedClayCentre === null && dob === '') {
        showToast('Please enter all mandatory fields');
        return;
      } else if (childname === '') {
        showToast('Please enter child name');
        return;
      } else if (dob === '') {
        showToast('Please select the date of birth');
        return;
      } else {
        showToast('Please select day care center');
        return;
      }
    }
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      logEvent(
        ANALYTICS_EVENT_TYPES.MI_CHILD_CARE_REGISTRATION,
        '',
        ANALYTICS_ITEM_NAMES.MI_CHILD_CARE
      );
      const formData = new URLSearchParams();
      formData.append('child_name', childname);
      formData.append('center_id', selectedClayCentre?.center_id);
      formData.append('date_of_birth', dob);
      formData.append('acknowledged_text', acknowledgementText);
      formData.append('only_validation', 0);
      formData.append('center_name', centerName);
      formData.append('city_name', city);
      formData.append('pincode', pincode === null ? '' : pincode);
      const apiResponse = await apiRequest(CHILD_CARE_APPLY, REQUEST_TYPE.POST, formData);
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response) {
            setRequestResp(apiResponse?.response || null);
            setShowDayCareRequestDialogue(false);
            setShowDayCareRequestSuccessDialogue(true);
          }
        }
      }
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    } catch (err) {
      showToast(err);
      // handleError(err, {}, ARTICLE_BY_ID + articleId, NAVIGATION_ROUTES.MICROBIZ_DETAIL);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [
    acknowledgementPeragraphs,
    childname,
    dob,
    centerName,
    city,
    pincode,
    age,
    dispatch,
    employeeData.first_name,
    employeeData.last_name,
    employeeData.middle_name,
    endDate,
    selectedClayCentre,
    selectedEndMonth,
    selectedStartMonth,
  ]);

  const callRegisterApiValidation = useCallback(async () => {
    let acknowledgementText = '';
    acknowledgementPeragraphs.map((peragraph) => (acknowledgementText += `${peragraph}\n`));
    acknowledgementText += `Employee Signature: ${employeeData.first_name} ${employeeData.middle_name} ${employeeData.last_name}`;

    const ageInMonths = (calculateAge(dob)?.year || 0) * 12 + (calculateAge(dob)?.month || 0);

    if (
      childname === '' ||
      selectedClayCentre === null ||
      dob === '' ||
      selectedClayCentre?.center_id === '1'
    ) {
      if (childname === '' && selectedClayCentre === null && dob === '') {
        showToast('Please enter all mandatory fields');
        return;
      } else if (childname === '' && dob === '') {
        showToast('Please enter child name and date of birth');
        return;
      } else if (childname === '' && selectedClayCentre === null) {
        showToast('Please enter child name and day care center');
        return;
      } else if (dob === '' && selectedClayCentre === null) {
        showToast('Please select date of birth and day care center');
        return;
      } else if (childname === '') {
        showToast('Please enter child name');
        return;
      } else if (dob === '') {
        showToast('Please select the date of birth');
        return;
      } else if (selectedClayCentre?.center_id === '1' && centerName === '' && city === '') {
        showToast('Please enter center name and city');
        return;
      } else if (selectedClayCentre?.center_id === '1' && centerName === '') {
        showToast('Please enter center name');
        return;
      } else if (selectedClayCentre?.center_id === '1' && city === '') {
        showToast('Please enter city name');
        return;
      } else if (selectedClayCentre === null) {
        showToast('Please select day care center');
        return;
      }
    }
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      logEvent(
        ANALYTICS_EVENT_TYPES.MI_CHILD_CARE_REGISTRATION,
        '',
        ANALYTICS_ITEM_NAMES.MI_CHILD_CARE
      );
      const formData = new URLSearchParams();
      formData.append('child_name', childname);
      formData.append('center_id', selectedClayCentre?.center_id);
      formData.append('date_of_birth', dob);
      formData.append('acknowledged_text', acknowledgementText);
      formData.append('only_validation', 1);
      formData.append('center_name', centerName);
      formData.append('city_name', city);
      formData.append('pincode', pincode);
      console.log('formdata', formData);
      const apiResponse = await apiRequest(CHILD_CARE_APPLY, REQUEST_TYPE.POST, formData);
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          SetConfirmationModel(true);
          setShowDayCareRequestDialogue(false);
        } else {
          setErrormsg(apiResponse?.response?.error);
          setErrorModel(true);
        }
      }
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    } catch (err) {
      //showToast(err);
      setErrormsg(err);
      setErrorModel(true);
      SetConfirmationModel(false);
      setShowDayCareRequestDialogue(true);
      // handleError(err, {}, ARTICLE_BY_ID + articleId, NAVIGATION_ROUTES.MICROBIZ_DETAIL);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [
    acknowledgementPeragraphs,
    childname,
    centerName,
    city,
    pincode,
    dob,
    age,
    dispatch,
    employeeData.first_name,
    employeeData.last_name,
    employeeData.middle_name,
    endDate,
    selectedClayCentre,
    selectedEndMonth,
    selectedStartMonth,
  ]);

  const callWithdrawApi = useCallback(async () => {
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      logEvent(
        ANALYTICS_EVENT_TYPES.MI_CHILD_CARE_WITHDRAW,
        currentItem?.request_id || '',
        ANALYTICS_ITEM_NAMES.MI_CHILD_CARE
      );
      const formData = new URLSearchParams();
      formData.append('request_id', currentItem?.request_id || '');
      const apiResponse = await apiRequest(CHILD_CARE_WITHDRAW, REQUEST_TYPE.POST, formData);
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response) {
            showToast(apiResponse?.response?.message || 'Application withdrawn successfully.');
            setCurrentItem(null);
            callMiChildCareApi();
          }
        }
      }
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    } catch (err) {
      // handleError(err, {}, ARTICLE_BY_ID + articleId, NAVIGATION_ROUTES.MICROBIZ_DETAIL);

      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [callMiChildCareApi, currentItem?.request_id, dispatch]);

  useEffect(() => {
    callCentresListApi();
  }, [callCentresListApi]);

  const onChangeText = (event) => {
    setErrorModel(false);
    let name = event?.target?.value;
    setChildName(name?.trim());
  };

  function calculateAge(dob) {
    const birthDate = new Date(dob);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    // Calculate the adjusted month difference
    let month = monthDifference < 0 ? 12 + monthDifference : monthDifference;

    return { year: age, month: month };
  }

  const handleDateChange = (e) => {
    setDisableButton(false);
    setErrorModel(false);
    const selectedDate = new Date(e.target.value);
    const today = new Date();
    if (selectedDate > today) {
      showToast('Future dates are not allowed.');
      return;
    } else {
      const { year, month } = calculateAge(e.target.value);
      if (year === 0 && month === 0) {
        setYear(0); // Set year to an empty string
        setMonth(0); // Set month to an empty string
      } else if (year === 0 && month > 0) {
        setYear(0); // Set year to an empty string
        setShowAge(true);
        setMonth(month); // Set month to the calculated month
      } else {
        setYear(year);
        setShowAge(true);
        setMonth(month);
      }

      setDob(e.target.value);
      const ageInMonths =
        (calculateAge(e.target.value)?.year || 0) * 12 + (calculateAge(e.target.value)?.month || 0);
      setAge(ageInMonths);
      if (
        validationData.max_age_month - ageInMonths < validationData.warning_after_month &&
        validationData.max_age_month - ageInMonths >= 0
      ) {
        setWarning(true);
        const newDate = validationData.max_age_month - ageInMonths;
        const { years, months } = convertMonthsToYearsAndMonths(newDate);

        let ageMessage = 'Benefit can be availed for ';

        if (years > 0) {
          ageMessage += `${years} ${years === 1 ? 'year' : 'years'}`;
        }

        if (months > 0) {
          ageMessage +=
            years > 0
              ? ` and ${months} ${months === 1 ? 'month' : 'months'}`
              : `${months} ${months === 1 ? 'month' : 'months'}`;
        }
        if (years > 0) {
          ageMessage += `${years} ${years === 1 ? 'year)' : 'years)'}`;
        }
        if (years === 0 && months === 0) {
          ageMessage = '';
          setWarning(false);
        }
        setWarningmsg(ageMessage);
      } else if (
        ageInMonths > validationData.max_age_month &&
        ageInMonths !== validationData.max_age_month
      ) {
        setWarning(true);
        let newMsg = `Child age must be in between ${validationData.min_age_month} month to `;
        const { years, months } = convertMonthsToYearsAndMonths(validationData.max_age_month);
        if (years > 0) {
          newMsg += `${years} ${years === 1 ? 'year' : 'years'}`;
        }

        if (months > 0) {
          newMsg +=
            years > 0
              ? ` and ${months} ${months === 1 ? 'month' : 'months'}`
              : `${months} ${months === 1 ? 'month' : 'months'}`;
        }
        newMsg += '.';
        if (years === 0 && months === 0) {
          newMsg = '';
          showWaring(false);
          setWarningmsg('');
        }
        setWarningmsg(newMsg);
        setDisableButton(true);
      } else if (
        ageInMonths < validationData.min_age_month &&
        ageInMonths !== validationData.min_age_month
      ) {
        setWarning(true);
        let newMsg = `Child age must be in between ${validationData.min_age_month} month to `;
        const { years, months } = convertMonthsToYearsAndMonths(validationData.max_age_month);
        if (years > 0) {
          newMsg += `${years} ${years === 1 ? 'year' : 'years'}`;
        }

        if (months > 0) {
          newMsg +=
            years > 0
              ? ` and ${months} ${months === 1 ? 'month' : 'months'}`
              : `${months} ${months === 1 ? 'month' : 'months'}`;
        }
        newMsg += '.';
        if (years === 0 && months === 0) {
          newMsg = '';
          showWaring(false);
          setWarningmsg('');
        }
        setWarningmsg(newMsg);
        setDisableButton(true);
      } else {
        setWarning(false);
      }
    }
  };

  const onChangeTextCity = (event) => {
    const value = event?.target?.value;
    setCity(value);
  };
  const onChangeTextPincode = (event) => {
    setErrorModel(false);
    const value = event?.target?.value;

    const numericValue = value.replace(/[^0-9]/g, '').slice(0, 6);
    event.target.value = numericValue;
    if (numericValue.length === 6) {
      setPincode(numericValue);
    } else if (numericValue.length > 0) {
      setErrorModel(true);
      setErrormsg('Pincode must be 6 digits long.');
    }
  };
  const onChangeTextklay = (event) => {
    const value = event?.target?.value;
    setCenterName(value);
  };
  const requestCareModal = () => (
    <Modal
      show={showDayCareRequestDialogue}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setShowDayCareRequestDialogue(false)}>
      <Modal.Body>
        <Modal.Title>Request for Childcare benefit</Modal.Title>
        <hr />
        <div>
          In case you are interested in enrolling your little one, please fill out the details below
        </div>
        <div className="mb-3">
          <div className="mt-2">Name of child *</div>
          <input
            maxLength={100}
            type="text"
            className="michildcare-name w-100 pl-2"
            onChange={onChangeText}
          />
          <div className="mt-2">Date of Birth *</div>
          <div className="d-flex flex-column  w-100">
            <CustomTextInput
              isTitle={false}
              placeholder="Time"
              name="pref_time"
              type="Date"
              value={dob}
              onChange={handleDateChange}
            />
            {showAge && (
              <div className="childCareYearContainer111">
                {showAge && (
                  <label className="fs-15 childCareYearContainer">
                    {year > 0 && month > 0
                      ? `Age: ${year} years ${month} months`
                      : year > 0
                      ? `Age: ${year} year${year > 1 ? 's' : ''}`
                      : month > 0
                      ? `Age: ${month} month${month > 1 ? 's' : ''}`
                      : null}
                  </label>
                )}

                {showWaring && <label style={{ color: '#4472C4' }}>{warningmsg}</label>}
              </div>
            )}
          </div>
          <div>
            <div className="mt-2">Preferred day care facility *</div>
            <Select
              value={selectedClayCentre}
              placeholder="Select"
              onChange={(ch) => {
                setErrorModel(false);
                console.log('choise ', ch);
                setSelectedClayCentre(ch);
              }}
              options={centres}
              className="w-100"
              defaultValue={0}
              isSearchable={false}
            />
          </div>

          {selectedClayCentre?.center_id === '1' && (
            <div>
              <div className="mt-2">Center name *</div>
              <input
                maxLength={100}
                type="text"
                className="michildcare-name w-100 pl-2"
                onChange={onChangeTextklay}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  boxSizing: 'border-box',
                }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: '50%',
                  }}>
                  <label className="">City *</label>
                  <input
                    maxLength={100}
                    type="text"
                    className="michildcare-name pl-2 w-100"
                    onChange={onChangeTextCity}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: '50%',
                    marginLeft: '10px',
                  }}>
                  <label className="">Pincode *</label>
                  <input
                    maxLength={6}
                    type="text"
                    className="michildcare-name pl-2 w-100"
                    onChange={onChangeTextPincode}
                  />
                </div>
              </div>
            </div>
          )}

          {showErrorModel && <span className="errorViewText">{errorMsg}</span>}
        </div>
        <hr />
        <div className="michildcare-footer-btn-container">
          <button
            className={
              disableButton ? 'michildcare-modal-buttons-disable' : 'michildcare-modal-buttons'
            }
            onClick={() => {
              disableButton ? () => {} : callRegisterApiValidation();
            }}>
            SUBMIT
          </button>
          <div className="ml-3" />
          <button
            onClick={() => {
              setShowDayCareRequestDialogue(false);
              setDob('');
              setYear(0);
              setMonth(0);
              setWarning(false);
              setWarningmsg('');
              setSelectedClayCentre(null);
              setErrorModel(false);
              setDisableButton(false);
              setCenterName('');
              setCity('');
              setChildName('');
              setPincode(null);
            }}
            className="michildcare-modal-buttons-no">
            CANCEL
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );

  const requestSuccessModal = () => (
    <Modal
      show={showDayCareRequestSuccessDialogue}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="sm"
      onHide={() => {
        setShowDayCareRequestSuccessDialogue(false);
        callMiChildCareApi();
      }}>
      <Modal.Body>
        <div className="michildcare-text-centre">
          <div className="michildcare-modal-title">Submitted successfully!</div>
          <div>{requestResp?.message || 'Your request is sent for an approval.'} </div>
        </div>
        <div className="michildcare-confirmation-modal-btn-container">
          <LoadMoreBtn
            btnText="OK"
            onClick={() => {
              setRequestResp(null);
              setShowDayCareRequestSuccessDialogue(false);
              callMiChildCareApi();
            }}
            buttonStyle="michildcare-modal-buttons"
          />
        </div>
      </Modal.Body>
    </Modal>
  );

  const acknowladgmentModal = () => (
    <Modal
      show={showAcknowladgment}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
      onHide={() => {
        setShowAcknowladgment(false);
      }}>
      <Modal.Body className="p-0">
        <div>
          <div className="acknowledgment-borders mb-2">
            <div className="michildcare-acknowledgement-title">ACKNOWLEDGEMENT</div>
          </div>
          <div className="p-3">
            {acknowledgementPeragraphs.map((peragraph, index) => (
              <div key={index} className="estore-regular-text mb-3">
                {peragraph}
              </div>
            ))}
            <div className="estore-regular-text">
              {`Employee Signature: ${employeeData.first_name} ${employeeData.middle_name} ${employeeData.last_name}`}
            </div>
          </div>
        </div>
        <div className="michildcare-confirmation-modal-btn-container pb-3">
          {/* <LoadMoreBtn
            btnText="I AGREE"
            onClick={() => {
              setRequestResp(null);
              setShowAcknowladgment(false);
              callRegisterApi();
            }}
            buttonStyle="michildcare-modal-buttons"
          /> */}
          <div className="michildcare-footer-btn-container">
            <button
              className={
                disableButton ? 'michildcare-modal-buttons-disable' : 'michildcare-modal-buttons'
              }
              onClick={() => {
                setRequestResp(null);
                setShowAcknowladgment(false);
                callRegisterApi();
              }}>
              I AGREE
            </button>
            <div className="ml-3" />
            <button
              onClick={() => {
                setShowAcknowladgment(false);
              }}
              className="michildcare-modal-buttons-no">
              CANCEL
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );

  const withdrawConfirmationModal = () => (
    <Modal
      show={showWithdrawConfirmation}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="sm"
      onHide={() => setShowWithdrawConfirmation(false)}>
      <Modal.Body>
        <div className="michildcare-text-centre">
          <div className="michildcare-modal-title">Confirmation</div>
          <div>{`Are you sure?`} </div>
          <div>{`You would like to withdraw this request.`} </div>
        </div>
        <div className="michildcare-confirmation-modal-btn-container">
          <button
            onClick={() => {
              setShowWithdrawConfirmation(false);
            }}
            className="michildcare-modal-buttons-no">
            NO
          </button>
          <div className="ml-3" />
          <button
            onClick={() => {
              setShowWithdrawConfirmation(false);
              callWithdrawApi();
            }}
            className="michildcare-modal-buttons">
            YES
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );

  const ValidationConfirmationModal = () => (
    <Modal
      show={confirmation}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="sm"
      onHide={() => SetConfirmationModel(false)}>
      <Modal.Body>
        <div className="michildcare-text-centre">
          <div className="michildcare-modal-title">Confirmation</div>
          <div>{`Are you sure?`} </div>
          <div>{`You would like to continue this request.`} </div>
        </div>
        <div className="michildcare-confirmation-modal-btn-container">
          <button
            onClick={() => {
              SetConfirmationModel(false);
            }}
            className="michildcare-modal-buttons-no">
            NO
          </button>
          <div className="ml-3" />
          <button
            onClick={() => {
              setShowAcknowladgment(true);
              SetConfirmationModel(false);
            }}
            className="michildcare-modal-buttons">
            YES
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );

  const formatAge = (months) => {
    if (months === 0) {
      return '0';
    }
    if (months < 12 && months > 0) {
      // 1 st if block
      if (months === 1) {
        return `1 month`;
      } else {
        return `${months} months`;
      }
    } else {
      // 2nd block
      if (months >= 12) {
        if (months === 12) {
          return '1 year';
        } else {
          return `${parseInt(months / 12)} years ${months % 12} months`;
        }
      }
    }
  };

  const getStatusTextWithColors = (status) => {
    switch (status) {
      case '0':
        return 'Waiting for admin approval';
      case '1':
        return 'Approved';
      case '2':
        return 'Rejected';
      case '3':
        return 'Withdrawn';
      case '4':
        return 'Waitlisted';
      default:
        return '';
    }
  };

  const getStatusColors = (status) => {
    switch (status) {
      case '0':
        return 'orange';
      case '1':
        return 'green';
      case '2':
        return 'red';
      case '3':
        return 'black';
      case '4':
        return '#4472C4';
      default:
        return '';
    }
  };

  return (
    <div>
      {showRegisterButton && (
        <p>
          In case you are interested in enrolling your little one, please click on the button below:
        </p>
      )}
      {showRegisterButton && (
        <button
          className="michildcare-modal-buttons"
          onClick={() => setShowDayCareRequestDialogue(true)}>
          Request for Childcare benefit
        </button>
      )}

      <hr />
      <p className="michildcare-yourrequests-text">YOUR REQUESTS</p>

      <table className="michildcare-table" cellPadding={2} border={1}>
        <tbody>
          <tr className="michildcare-tr michildcare-bg">
            {tableColumnHeadings?.map((columnHead) => (
              <td key={columnHead} className="michildcare-column-titles michildcare-td">
                {columnHead}
              </td>
            ))}
          </tr>
          {data?.length == 0 && (
            <tr className="michildcare-no-data">
              <td colSpan={8} className="michildcare-td">
                No request exists.
              </td>
            </tr>
          )}
          {data?.map((data_) => {
            return (
              <tr key={data_?.request_id} className="michildcare-tr">
                <td className="michildcare-td">{data_?.child_name || ''}</td>
                <td className="michildcare-td">{getDateInDDMMMYYYY(data_?.date_of_birth || '')}</td>
                <td className="michildcare-td">
                  {getDateInDDMMMYYYY(data_?.approved_start_date || '') === 'Invalid Date'
                    ? ''
                    : getDateInDDMMMYYYY(data_?.approved_start_date || '')}
                </td>
                <td className="michildcare-td">
                  {getDateInDDMMMYYYY(data_?.approved_end_date || '') === 'Invalid Date'
                    ? ''
                    : getDateInDDMMMYYYY(data_?.approved_end_date || '')}
                </td>
                <td className="michildcare-td">{data_?.center_name || ''}</td>
                <td className="michildcare-td">
                  {getDateInDDMMMYYYY(data_?.requested_on || '') === 'Invalid Date'
                    ? ''
                    : getDateInDDMMMYYYY(data_?.requested_on || '')}
                </td>
                <td className="michildcare-td" style={{ color: 'black' }}>
                  <span style={{ color: getStatusColors(data_?.status) || 'black' }}>
                    {getStatusTextWithColors(data_?.status) || ''}
                  </span>
                </td>
                <td>
                  {(data_?.status == 0 || data_?.status == 4) && (
                    <button
                      onClick={() => {
                        setCurrentItem(data_);
                        setShowWithdrawConfirmation(true);
                      }}
                      className="michildcare-modal-buttons m-1">
                      WITHDRAW
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {ValidationConfirmationModal()}
      {requestCareModal()}
      {requestSuccessModal()}
      {acknowladgmentModal()}
      {withdrawConfirmationModal()}
    </div>
  );
}
