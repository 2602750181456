import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectMicareListData, selectMichildcareTabTitles } from '../../state/MasterData';
import { saveProgressLoadingState, selectBaseUrl } from '../../state/UIState';
import { Tab } from 'react-bootstrap';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  MI_CHILD_CARE_TAB_KEYS,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import NavTabs from '../../common/ui/tabs/NavTabs';
import AboutTab from './tabs/AboutTab';
import PolicyTab from './tabs/PolicyTab';
import CostDetailsTab from './tabs/CostDetailsTab';
import RequestsTab from './tabs/RequestsTab';
import { apiRequest } from '../../services/Service';
import { GET_MI_CHILD_CARE } from '../../services/ApiUrls';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { selectAccessData } from '../../state/UserAccessData';

const ChildCareContentScreen = () => {
  const [data, setData] = useState({});
  const michildcareTabs = useSelector(selectMichildcareTabTitles);
  const anniversaryDayTitles = useSelector(selectMicareListData);
  const accessData = useSelector(selectAccessData);
  const [showRegisterTab, SetShowRegisterTab] = useState(false);
  const [showRegisterButton, SetShowRegisterButtton] = useState(false);
  const [max_age_month, setMax_age_month] = useState();
  const [min_age_month, setMin_age_month] = useState();
  const [warning_after_month, setWarning_after_month] = useState();

  const micareAccessData = accessData?.data?.find((item) => item.feature_key === '_michildcare');

  useEffect(() => {
    if (micareAccessData) {
      const actions = micareAccessData?.actions;
      SetShowRegisterTab(actions?.includes('_requests_tab'));
      SetShowRegisterButtton(actions?.includes('_register'));
    }
  }, [micareAccessData]);

  const [activeTab, setActiveTab] = useState('');
  const dispatch = useDispatch();
  const baseUrl = useSelector(selectBaseUrl);
  const activeRoutes = [];
  for (var val in michildcareTabs) {
    if (michildcareTabs[val].active) {
      activeRoutes.push({
        key: michildcareTabs[val].key,
        title: michildcareTabs[val].title.toUpperCase(),
      });
    }
  }
  const [routes] = useState(activeRoutes);
  useEffect(() => {
    if (routes?.length) {
      setActiveTab(routes?.[0]?.key);
    }
  }, [routes]);

  useEffect(() => {
    // Analytics
    trackScreen(ANALYTICS_SCREEN_NAMES.MI_CHILD_CARE, NAVIGATION_ROUTES.MI_CHILD_CARE);
  }, []);

  const callMiChildCareApi = useCallback(async () => {
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      logEvent(
        ANALYTICS_EVENT_TYPES.MI_CHILD_CARE_GET_DATA,
        '',
        ANALYTICS_ITEM_NAMES.MI_CHILD_CARE
      );
      const apiResponse = await apiRequest(GET_MI_CHILD_CARE, REQUEST_TYPE.GET, {});
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response) {
            const data = apiResponse?.response?.data?.feeds || {};
            setMax_age_month(apiResponse?.response?.data?.feeds?.max_age_month);
            setMin_age_month(apiResponse?.response?.data?.feeds?.min_age_month);
            setWarning_after_month(apiResponse?.response?.data?.feeds?.warning_after_month);
            setData(data);
          }
        }
      }
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    } catch (err) {
      // handleError(err, {}, ARTICLE_BY_ID + articleId, NAVIGATION_ROUTES.MICROBIZ_DETAIL);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch]);

  useEffect(() => {
    callMiChildCareApi();
  }, [callMiChildCareApi]);

  const renderTabs = (tab) => {
    switch (tab?.key) {
      case MI_CHILD_CARE_TAB_KEYS.ABOUT:
        return <AboutTab data={data?.about} />;
      case MI_CHILD_CARE_TAB_KEYS.POLICY:
        return <PolicyTab data={data?.policy} />;
      case MI_CHILD_CARE_TAB_KEYS.COST_DETAILS:
        return <CostDetailsTab data={data?.cost_details} />;
      case MI_CHILD_CARE_TAB_KEYS.REQUESTS:
        return (
          showRegisterTab && (
            <RequestsTab
              data={data?.requests}
              callMiChildCareApi={callMiChildCareApi}
              validationData={{ max_age_month, min_age_month, warning_after_month }}
            />
          )
        );
      default:
        null;
    }
  };

  const renderChildCareScreen = () => {};

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MI_CHILD_CARE} />
      <BackButton />
      {baseUrl !== '' && (
        <NavTabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
          {routes &&
            routes?.map((tab, index) => {
              if (tab.key === MI_CHILD_CARE_TAB_KEYS.REQUESTS) {
                return (
                  showRegisterTab && (
                    <Tab
                      key={`${index}`}
                      eventKey={tab?.key}
                      title={tab?.title}
                      className="main-content-container">
                      {renderTabs(tab, index)}
                    </Tab>
                  )
                );
              } else {
                return (
                  <Tab
                    key={`${index}`}
                    eventKey={tab?.key}
                    title={tab?.title}
                    className="main-content-container">
                    {renderTabs(tab, index)}
                  </Tab>
                );
              }
            })}
        </NavTabs>
      )}
    </div>
  );
};

export default ChildCareContentScreen;
