import React, { useCallback, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { useLocation, useHistory } from 'react-router';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FieldArray } from 'formik';

import {
  selectPropertyTypeList,
  selectTravellerData,
} from '../../../state/TravelRequestMasterData';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ACTION_TYPES,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
} from '../../../common/constants/AppConstants';
import {
  getDateInYYYYMMDD,
  getFutureYearDateInYYYYMMDD,
  getTomorrowDateInYYYYMMDD,
} from '../../../utils/common';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import { selectEmployeeData } from '../../../state/EmployeeData';
import { selectMultilineTextSize } from '../../../state/MasterData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import CustomTextInput from '../../../common/ui/custom_text_input/CustomTextInput';
import Divider from '../../../common/ui/divider/Divider';
import CreateChooseTravellerDialog from './CreateChooseTravellerDialog';
import AddEditTravellerDialog from './AddEditTravellerDialog';

const AccommodationSubTab = (props) => {
  const {
    formikProps,
    accommodationFieldArrayProps,
    accommodationLength,
    accommodationIndex,
    isPreApprovedRequest,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const accessibilityData = useSelector(selectAccessibilityData);
  const employeeData = useSelector(selectEmployeeData);
  const travellerData = useSelector(selectTravellerData);
  const maxMultiLineCharLimit = useSelector(selectMultilineTextSize);
  const properTypeList = useSelector(selectPropertyTypeList);
  const tomorrowDate = getTomorrowDateInYYYYMMDD();
  const maxAllowedDate = getFutureYearDateInYYYYMMDD(5);
  const [isAddGuestDialogVisible, setIsAddGuestDialogVisible] = useState(false);
  const [isAddEditTravellerModalVisible, setIsAddEditTravellerModalVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [travellerDetails, setTravellerDetails] = useState({});
  const guestsFieldArrayPropRef = useRef();

  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.isEditTraveller &&
      location.state.travellerDetails &&
      Object.keys(location.state.travellerDetails) &&
      Object.keys(location.state.travellerDetails).length > 0
    ) {
      if (
        formikProps.values.accommodations[accommodationIndex].travellers &&
        formikProps.values.accommodations[accommodationIndex].travellers.length > 0 &&
        location.state.travellerDetails.employee_code
      ) {
        formikProps.values.accommodations[accommodationIndex].travellers.map(
          (guest, guestIndex) => {
            if (
              guest &&
              guest.employee_code &&
              guest.employee_code.toString() ===
                location.state.travellerDetails.employee_code.toString()
            ) {
              guestsFieldArrayPropRef.current.replace(guestIndex, location.state.travellerDetails);
            }
          }
        );
        if (
          travellerData &&
          travellerData.employee_code &&
          travellerData.employee_code.toString() ===
            location.state.travellerDetails.employee_code.toString()
        ) {
          dispatch({ type: ACTION_TYPES.GET_TRAVEL_REQUEST_MASTER_DATA, payload: {} });
        }
        history.replace(NAVIGATION_ROUTES.TRAVEL_REQUEST, {
          travellerDetails: undefined,
          isEditTraveller: undefined,
        });
      }
    }
  }, [
    accommodationIndex,
    dispatch,
    formikProps.values.accommodations,
    history,
    location,
    travellerData,
  ]);

  const onAddEditTraveller = (travellerDetails) => {
    guestsFieldArrayPropRef.current.push(travellerDetails);
  };

  const onCloseCreateChooseDialog = useCallback(() => {
    setIsAddGuestDialogVisible(false);
  }, []);

  const onPressCreateTraveller = () => {
    setIsEdit(false);
    setIsAddGuestDialogVisible(false);
    setIsAddEditTravellerModalVisible(true);
  };

  const commentPopover = (
    <Popover>
      <Popover.Content>
        <div className="common-cursor-pointer">
          <p className="mb-0 common-ws-pre-line">
            E.g., Preferred location/Nearest landmarks/Accessible areas/Other requirements
          </p>
        </div>
      </Popover.Content>
    </Popover>
  );

  const pushNewAccomodationForm = () => {
    accommodationFieldArrayProps.push({
      accommodation_booking_id: '',
      destination: '',
      check_in_date: '',
      check_out_date: '',
      property_type_id: '',
      number_of_rooms: 0,
      number_adults: 0,
      number_childrens: 0,
      comments: '',
      travellers:
        formikProps.values.on_behalf === 'E' &&
        formikProps.values.employee &&
        Object.keys(formikProps.values.employee) &&
        Object.keys(formikProps.values.employee).length > 0 &&
        formikProps.values.employee.employee_code !== employeeData.employee_code
          ? [formikProps.values.employee]
          : [travellerData],
    });
  };

  const onPressAddAccomodationHandler = () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.TR_PLAN_TRAVEL_ADD_ANOTHER_ACCOM_CLICK,
      formikProps.values.request_id.toString(),
      ANALYTICS_ITEM_NAMES.TRAVEL_REQUEST
    );
    pushNewAccomodationForm();
  };

  const onPressEditHandler = (traveller) => {
    setIsEdit(true);
    setIsAddEditTravellerModalVisible(true);
    setTravellerDetails(traveller);
  };

  return (
    <div className="travel-request-selected-type-form pl-3 pr-3 pb-3">
      <div>
        {accommodationIndex > 0 && <Divider style="mb-1" />}
        <div className="pt-2">
          <CustomTextInput
            name={`accommodations.${accommodationIndex}.destination`}
            title="Destination"
            placeholder="Enter city"
            maxLength={maxMultiLineCharLimit}
            value={formikProps.values.accommodations[accommodationIndex].destination}
            onBlur={formikProps.handleBlur}
            onChange={formikProps.handleChange}
          />
        </div>
        <div className="d-flex">
          <div className="flex-fill mr-2">
            <CustomTextInput
              name={`accommodations.${accommodationIndex}.check_in_date`}
              title="Check In"
              placeholder="Check in date"
              type="date"
              min={
                isPreApprovedRequest && formikProps.values.start_date
                  ? getDateInYYYYMMDD(formikProps.values.start_date)
                  : tomorrowDate
              }
              max={
                isPreApprovedRequest && formikProps.values.end_date
                  ? getDateInYYYYMMDD(formikProps.values.end_date)
                  : maxAllowedDate
              }
              value={formikProps.values.accommodations[accommodationIndex].check_in_date}
              onChange={formikProps.handleChange}
              style="my-profile-edit-editable-text-input"
            />
          </div>
          <div className="flex-fill ml-2">
            <CustomTextInput
              name={`accommodations.${accommodationIndex}.check_out_date`}
              title="Check Out"
              placeholder="Check out date"
              type="date"
              min={
                formikProps.values.accommodations[accommodationIndex].check_in_date
                  ? formikProps.values.accommodations[accommodationIndex].check_in_date
                  : isPreApprovedRequest && formikProps.values.start_date
                  ? getDateInYYYYMMDD(formikProps.values.start_date)
                  : tomorrowDate
              }
              max={
                isPreApprovedRequest && formikProps.values.end_date
                  ? getDateInYYYYMMDD(formikProps.values.end_date)
                  : maxAllowedDate
              }
              value={formikProps.values.accommodations[accommodationIndex].check_out_date}
              onChange={formikProps.handleChange}
              style="my-profile-edit-editable-text-input"
            />
          </div>
        </div>
        <Form.Label className="travel-request-plan-travel-label">Property Type</Form.Label>
        <Select
          className="travel-request-dropdown-style"
          placeholder="Select"
          isSearchable={false}
          options={properTypeList}
          getOptionLabel={(option) => option.title}
          getOptionValue={(option) => option.pref_id}
          value={properTypeList.find(
            (option) =>
              option.pref_id ===
              formikProps.values.accommodations[accommodationIndex].property_type_id
          )}
          onChange={(selectedOption) => {
            formikProps.setFieldValue(
              `accommodations.${accommodationIndex}.property_type_id`,
              selectedOption.pref_id
            );
          }}
        />

        <FieldArray name={`accommodations[${accommodationIndex}].travellers`}>
          {(travellersFieldArrayProps) => {
            guestsFieldArrayPropRef.current = travellersFieldArrayProps;
            return (
              <div>
                <Form.Label className="mt-3 travel-request-plan-travel-label">Guests</Form.Label>
                {formikProps.values.accommodations[accommodationIndex].travellers &&
                  formikProps.values.accommodations[accommodationIndex].travellers.length > 0 &&
                  formikProps.values.accommodations[accommodationIndex].travellers.map(
                    (traveller, travellerIndex) => (
                      <div
                        key={travellerIndex.toString()}
                        className="d-flex justify-content-between travel-request-travellers-view align-items-center mb-3 px-3">
                        <p className="mb-0">{traveller.first_name + ' ' + traveller.last_name}</p>
                        <div className="d-flex align-items-center">
                          <p
                            onClick={() => onPressEditHandler(traveller)}
                            className="mb-0 common-cursor-pointer travel-request-add-traveller">
                            EDIT
                          </p>
                          <div
                            className="common-cursor-pointer ml-3"
                            onClick={() => travellersFieldArrayProps.remove(travellerIndex)}>
                            <img
                              src={getFeatureComponentUrl(
                                accessibilityData,
                                ACCESSIBILITY_FEATURE_COMPONENTS.ASK_ORG_CLOSE,
                                ACCESSIBILITY_IDENTIFIERS.ASK_ORG
                              )}
                              className="travel-request-close-icon"
                            />
                          </div>
                        </div>
                      </div>
                    )
                  )}
                {isAddEditTravellerModalVisible && (
                  <AddEditTravellerDialog
                    isEdit={isEdit}
                    travellerDetails={travellerDetails}
                    isAddEditTravellerModalVisible={isAddEditTravellerModalVisible}
                    setIsAddEditTravellerModalVisible={setIsAddEditTravellerModalVisible}
                    onAddEditTraveller={onAddEditTraveller}
                  />
                )}
                {isAddGuestDialogVisible && (
                  <CreateChooseTravellerDialog
                    isCreateTravellerModalVisible={isAddGuestDialogVisible}
                    onCloseCreateChooseDialog={onCloseCreateChooseDialog}
                    onPressCreateTraveller={onPressCreateTraveller}
                    travellersFieldArrayProps={travellersFieldArrayProps}
                    accommodationIndex={accommodationIndex}
                    fromTab={'accommodations'}
                  />
                )}

                <div
                  className="d-flex align-items-center justify-content-center travel-request-add-traveller-view py-2 common-cursor-pointer"
                  onClick={() => {
                    logEvent(
                      ANALYTICS_EVENT_TYPES.TR_PLAN_TRAVEL_ADD_GUEST_CLICK,
                      formikProps.values.request_id.toString(),
                      ANALYTICS_ITEM_NAMES.TRAVEL_REQUEST
                    );
                    setIsAddGuestDialogVisible(true);
                  }}>
                  <div className="travel-request-add-traveller">+ ADD GUEST</div>
                </div>
              </div>
            );
          }}
        </FieldArray>

        <div className="mt-3">
          <div>
            <label>Comments</label>
            <OverlayTrigger rootClose trigger="click" placement="right" overlay={commentPopover}>
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_INFO,
                  ACCESSIBILITY_IDENTIFIERS.COMMON
                )}
                className="common-user-profile-info-icon common-cursor-pointer ml-2"
              />
            </OverlayTrigger>
          </div>
          <CustomTextInput
            name={`accommodations.${accommodationIndex}.comments`}
            isTitle={false}
            infoText="E.g., Preferred location/Nearest landmarks/Accessible areas/Other requirements"
            title="Comments"
            value={formikProps.values.accommodations[accommodationIndex].comments}
            as="textarea"
            maxLength={maxMultiLineCharLimit}
            onBlur={formikProps.handleBlur}
            onChange={formikProps.handleChange}
            style="my-profile-edit-editable-text-area"
          />
        </div>

        <div
          className="d-flex justify-content-end mb-3 common-cursor-pointer"
          onClick={() => {
            if (accommodationLength === 1) {
              accommodationFieldArrayProps.remove(accommodationIndex);
              pushNewAccomodationForm();
            } else {
              accommodationFieldArrayProps.remove(accommodationIndex);
            }
          }}>
          <div className="d-flex align-items-center">
            <div className="travel-request-delete-travel-accommodation mr-2">
              DELETE ACCOMMODATION
            </div>
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_TRASH_RED,
                ACCESSIBILITY_IDENTIFIERS.COMMON
              )}
              className="travel-request-delete-icon"
            />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center travel-request-add-traveller-view py-2">
        <div
          className="travel-request-add-traveller common-cursor-pointer"
          onClick={onPressAddAccomodationHandler}>
          + ADD ANOTHER ACCOMODATION
        </div>
      </div>
    </div>
  );
};

export default React.memo(AccommodationSubTab);
