import React from 'react';
import { useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
} from '../../../common/constants/AppConstants';

const LikeCommentShare = ({
  likeFunction,
  likePeopleFunction,
  commentFunction,
  commentCount,
  isLiked,
  likesCount,
  showLikeButton,
  showCommentButton,
  showShareButton,
  shareVideo,
}) => {
  const accessibilityData = useSelector(selectAccessibilityData);

  return (
    <div style={styles.iconRow}>
      {showLikeButton && (
        <>
          <div onClick={likeFunction} style={styles.likedViewSpacing}>
            <div style={styles.iconRow}>
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  isLiked
                    ? ACCESSIBILITY_FEATURE_COMPONENTS.CEO_CHAT_ACTIVE_LIKE
                    : ACCESSIBILITY_FEATURE_COMPONENTS.CEO_CHAT_INACTIVE_LIKE,
                  ACCESSIBILITY_IDENTIFIERS.CEO_CHAT
                )}
                style={styles.likeCommentImage}
                className="common-cursor-pointer"
              />
            </div>
          </div>
          <div onClick={likePeopleFunction} style={styles.commentButton}>
            {likesCount}
          </div>
        </>
      )}

      {showCommentButton && (
        <div onClick={commentFunction} style={styles.iconRow}>
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.CEO_CHAT_COMMENT,
              ACCESSIBILITY_IDENTIFIERS.CEO_CHAT
            )}
            style={styles.likeCommentImage}
            className="common-cursor-pointer"
          />
          <span style={styles.commentCountStyle}>{commentCount}</span>
        </div>
      )}

      {showShareButton ? (
        <div onClick={shareVideo} style={styles.iconRow}>
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_SHARE_IMAGE,
              ACCESSIBILITY_IDENTIFIERS.MICROGIVE
            )}
            className="common-cursor-pointer"
            style={styles.likeCommentImage}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const styles = {
  likeCommentImage: { height: '20px', width: '20px' },
  iconRow: { display: 'flex', alignItems: 'center', flexDirection: 'row' },
  commentCountStyle: {
    fontFamily: 'ProximaNova-Regular',
    fontSize: '14px',
    marginLeft: '8px',
    marginRight: '15px',
  },
  commentButton: {
    fontFamily: 'ProximaNova-Regular',
    fontSize: '14px',
    cursor: 'pointer',
    marginLeft: '8px',
    marginRight: '15px',
  },
};

export default LikeCommentShare;
