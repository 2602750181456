import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetLoaderState, saveProgressLoadingState } from '../../../../state/UIState';
import { apiRequest, handleError } from '../../../../services/Service';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  FEATURE_TYPE,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../../common/constants/AppConstants';
import { selectAnniversaryConfig } from '../../../../state/MasterData';
import { showToast } from '../../../../utils/common';
import { getFeatureComponentUrl } from '../../../../utils/accessibility';
import { selectAccessibilityData } from '../../../../state/AccessibilityData';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ML35VideoComponent from '../../components/ML35VideoComponent';
import CustomButton from '../../../../common/ui/custom_button/CustomButton';
import { COMMENT_BY_ID } from '../../../../services/ApiUrls';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import { Modal } from 'react-bootstrap';
import { VscChromeClose } from 'react-icons/vsc';
import ReactPlayer from 'react-player';
import {
  saveAnniversaryGeoData,
  saveSomTabData,
  selectAnniversaryGeoData,
} from '../../../../state/TabData';
import ML35GalleryComponent from '../../components/ML35GalleryComponent';
import HtmlView from '../../../../common/ui/html_view/HTMLView';
import LoadMoreBtn from '../../../../common/ui/load_more_btn/LoadMoreBtn';

const CelebrationsTab = ({ identifier, geo = 'INDIA' }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [contentType, setContentType] = useState('html');
  const [html, setHtml] = useState('');
  const anniversaryGeoData = useSelector(selectAnniversaryGeoData);
  const [selectedGeo, setSelectedGeo] = useState(
    anniversaryGeoData && anniversaryGeoData !== '' ? anniversaryGeoData : 'INDIA'
  );
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(true);

  const anniversaryConfig = useSelector(selectAnniversaryConfig).find(
    (element) => element.identifier === identifier
  );

  const somButtonConfig = anniversaryConfig?.som_button_config;

  const [MLDayContent, setMLDayContent] = useState([]);
  const [MLDayPictures, setMLDayPictures] = useState([]);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [show, setShow] = useState(false);
  const [pageId, setPageId] = useState(1);
  const [isOverlayOpen, setOverlayOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openOverlay = (item) => {
    setSelectedImage(item);
    setOverlayOpen(true);
  };

  const closeOverlay = () => {
    setOverlayOpen(false);
    setSelectedImage();
  };

  const downloadImage = async (imageSrc, imageName = 'downloaded-image') => {
    try {
      const response = await fetch(imageSrc);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = imageName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading the image:', error);
    }
  };

  const callMLDayContentApi = useCallback(
    async (geo, page_id) => {
      setLoadMoreEnabled(true);
      setError(false);
      setMLDayContent([]);
      if (page_id === 1) setMLDayPictures([]);
      setHtml('');
      try {
        dispatch(saveProgressLoadingState({ isProgressLoading: true }));
        if (geo === 'INDIA') {
          const params = { geography: geo };

          const apiResponse = await apiRequest(
            identifier + '/videogallery',
            REQUEST_TYPE.GET,
            params
          );
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              setMLDayContent(apiResponse.response?.data ?? []);
            }
          }
          dispatch(resetLoaderState());
        } else {
          const params = { geography: geo, page_id: page_id };

          const apiResponse = await apiRequest(
            identifier + '/picturegallery',
            REQUEST_TYPE.GET,
            params
          );
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response?.data[0]?.content_type === 'html') {
                setHtml(apiResponse.response?.data[0]?.html);
                setContentType(apiResponse.response?.data[0]?.content_type);
              } else {
                setContentType(apiResponse.response?.data[0]?.content_type);
                const tempPictures = apiResponse.response?.data[0]?.pictures ?? [];
                if (tempPictures.length === 0) {
                  setLoadMoreEnabled(false);
                }
                if (page_id > 1) {
                  setMLDayPictures((prev) => [...prev, ...tempPictures]);
                } else {
                  setMLDayPictures(tempPictures);
                }
              }
            }
          }
          dispatch(resetLoaderState());
        }
      } catch (err) {
        handleError(err, {}, identifier + '/videogallery', 'CelebrationsTab');
        dispatch(resetLoaderState());
        setError(false);
      }
    },
    [dispatch, identifier]
  );

  useEffect(() => {
    callMLDayContentApi(selectedGeo, 1);
  }, [callMLDayContentApi, selectedGeo]);

  const updateGeo = (geo) => {
    dispatch(saveAnniversaryGeoData(geo));
    setSelectedGeo(geo);
    callMLDayContentApi(geo, 1);
  };

  const loadMore = () => {
    if (loadMoreEnabled) {
      callMLDayContentApi(selectedGeo, pageId + 1);
      setPageId(pageId + 1);
    }
  };

  const callLikeApi = async (referId) => {
    setError(false);
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.LIKE_UPDATE,
      })
    );
    const params = new URLSearchParams();
    try {
      params.append('type', FEATURE_TYPE.ML35VID);
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + referId + '/like',
        REQUEST_TYPE.POST,
        params
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );

      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const message = apiResponse.response.message;
            if (message) {
              showToast(message);
              callMLDayContentApi(selectedGeo);
            }
          }
        }
      }
    } catch (err) {
      handleError(err, params, COMMENT_BY_ID + referId + '/like', NAVIGATION_ROUTES.THE_WALL);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
      setError(true);
    }
  };

  const onPressLikeButton = (item) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.ML35_VIDEO_LIKE,
      item.video_id.toString(),
      ANALYTICS_ITEM_NAMES.LIKE
    );
    callLikeApi(item.video_id, item.liked);
  };

  const onPressWhoLikedHandler = (item) => {
    logEvent(ANALYTICS_EVENT_TYPES.ML35_VIDEO_LIKED_COUNT, item.video_id, item.title);
    if (item.like_count > 0) {
      history.push(NAVIGATION_ROUTES.LIKED_PEOPLE, {
        referId: item.video_id,
        parentType: FEATURE_TYPE.ML35VID,
      });
    }
  };

  const onPressCommentButton = (item) => {
    logEvent(ANALYTICS_EVENT_TYPES.ML35_VIDEO_COMMENTS, item.video_id, item.video_title);
    history.push(NAVIGATION_ROUTES.COMMENTS, {
      referId: item.video_id,
      likeType: FEATURE_TYPE.ML35VID,
      fromRoute: NAVIGATION_ROUTES.ANNIVERSARY_SCREEN,
      commentCount: item.comment_count,
    });
  };

  const gotoPictures = (item) => {
    history.push(NAVIGATION_ROUTES.ML35_PICTURES_SCREEN, {
      identifier: identifier,
      geography: selectedGeo,
      day_id: item.day_id,
    });
  };

  const celebrationItem = (item, index) => {
    return (
      <div key={`${index}`} style={{ paddingTop: '10px', paddingBottom: '10px', width: '100%' }}>
        <div className="d-flex flex-row justify-content-between align-items-center w-100">
          <h2 style={{ color: '#000', fontSize: '20px', fontFamily: 'ProximaNovaCond-SemiBold' }}>
            {item.title}
          </h2>
          {anniversaryConfig.video_geos?.includes(selectedGeo) && (
            <div className="mb-2">
              <CustomButton onClick={() => gotoPictures(item)}>Pictures</CustomButton>
            </div>
          )}
        </div>
        <div
          style={{
            borderBottom: '1px solid #E30613',
            width: '100%',
            marginBottom: 5,
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: 10,
          }}>
          <span
            style={{
              color: '#4472C4',
              fontSize: '14px',
              fontFamily: 'ProximaNovaCond-SemiBold',
              marginBottom: '10px',
            }}>
            {item.sub_title}
          </span>
        </div>
        <div>
          {item.videos && item.videos.length > 0 ? (
            <div className="row">
              {item.videos.map((video, index) => (
                <ML35VideoComponent
                  onPressLikeButton={onPressLikeButton}
                  handleLikedPeople={onPressWhoLikedHandler}
                  onPressCommentButton={onPressCommentButton}
                  item={video}
                  key={index}
                />
              ))}
            </div>
          ) : (
            <div>No videos available</div>
          )}
        </div>
      </div>
    );
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onPressWallpapers = () => {
    history.push(NAVIGATION_ROUTES.WALL_PAPERS_SCREEN, {
      identifier: identifier,
    });
  };

  const onPressECards = () => {
    history.push(NAVIGATION_ROUTES.ECARDS, {
      tabKey: anniversaryConfig.ecard_id,
    });
  };

  const onPressSom = () => {
    dispatch(saveSomTabData(''));
    history.push({
      pathname: `${NAVIGATION_ROUTES.SOM_MAIN}/${somButtonConfig.som_id}`,
      state: {
        show_business_awardee: somButtonConfig.show_business_awardee,
        visible_tabs: somButtonConfig.visible_tabs,
      },
    });
  };

  const tabButtonComponent = () => {
    return (
      <div className="row h-100">
        {anniversaryConfig && anniversaryConfig?.wallpapers_active && (
          <>
            <div
              onClick={onPressWallpapers}
              className="celebration-tab-button-style common-cursor-pointer">
              WallPapers
            </div>
            <div className="vertical-divider-celebrations" />
          </>
        )}
        {anniversaryConfig && anniversaryConfig?.ecards_active && (
          <>
            <div
              onClick={onPressECards}
              className="celebration-tab-button-style common-cursor-pointer">
              e-Cards
            </div>
            <div className="vertical-divider-celebrations" />
          </>
        )}
        {somButtonConfig?.show_button && (
          <div onClick={onPressSom} className="celebration-tab-button-style common-cursor-pointer">
            {somButtonConfig?.som_button_text}
          </div>
        )}
      </div>
    );
  };

  return (
    <div style={{ flex: 1 }}>
      <div className="d-flex flex-row-reverse mi-hr-activity-btn-container">
        {tabButtonComponent()}
      </div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        className="home-fullscreen-modal-dialog fade-scale"
        aria-labelledby="example-custom-modal-styling-title">
        <Modal.Body className="animate-bottom">
          <div
            className="home-fullscreen-modal-dialog-close-button-container"
            onClick={handleClose}>
            <VscChromeClose size="4em" color="#fff" />
          </div>

          <ReactPlayer
            url={anniversaryConfig.celebrations_home_video}
            width="100%"
            height="100%"
            playing={true}
            controls={true}
            className="home-video-container"
          />
        </Modal.Body>
      </Modal>
      <div
        style={{
          height: '660px',
          width: '100%',
          backgroundImage: `url(${anniversaryConfig.celebration_home_image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}>
        <div className="geo-container common-cursor-pointer">
          {anniversaryConfig.geos?.map((item, index) => (
            <div className="geo-button" key={index} onClick={() => updateGeo(item)}>
              <div
                className={`geo-button-inner ${item === selectedGeo ? 'selected-geo-button' : ''}`}>
                <span className="geo-button-text">{item}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="mb-4">
          <div className="anniversary-celebration-video-inner-container">
            <div className="anniversary-celebration-play-button" onClick={handleShow}>
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_PLAY_VIDEO_WHITE,
                  ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
                )}
                className="anniversary-celebration-play-button-img"
              />
            </div>
            <img
              className="anniversary-celebration-video-thumbnail"
              src={anniversaryConfig.celebrations_home_video_thumbnail}
            />
          </div>
        </div>
      </div>
      <div style={{ padding: '20px' }}>
        {MLDayContent.map((item, index) => {
          return (
            <div className="row" key={`${index}`}>
              {celebrationItem(item, index)}
            </div>
          );
        })}
      </div>
      {MLDayPictures.length > 0 && (
        <>
          {isOverlayOpen && selectedImage && (
            <div className="ml-35-image-overlay">
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_IMAGE_CLOSE,
                  ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
                )}
                className="ml-35-image-close-button"
                onClick={closeOverlay}
              />
              <img
                className="ml-35-fullscreen-image"
                src={selectedImage.picture.base_url + 'xxhdpi/' + selectedImage.picture.image_path}
              />
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_IMAGE_DOWLOAD,
                  ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
                )}
                className="ml-35-image-download-icon common-cursor-pointer"
                onClick={() =>
                  downloadImage(
                    selectedImage.picture.base_url + 'xxhdpi/' + selectedImage.picture.image_path,
                    `Celebration-Image`
                  )
                }
              />
            </div>
          )}
          <div className={`ml-35-images-wrapper mb-2`}>
            {MLDayPictures.map((item, index) => (
              <ML35GalleryComponent
                key={index}
                item={item}
                index={index}
                onPress={() => openOverlay(item)}
              />
            ))}
          </div>
          {loadMoreEnabled && (
            <div className="mb-4 mt-4">
              <LoadMoreBtn onClick={loadMore} />
            </div>
          )}
        </>
      )}

      {!!html && contentType === 'html' && <HtmlView html={html} htmlStyle="ml-35-html-text" />}
    </div>
  );
};

export default CelebrationsTab;
