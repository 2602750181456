import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  HOME_SECTION_TEMPLATES,
  HOME_WIDGET_IDENTIFIERS,
} from '../../../common/constants/AppConstants';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import ClassicText from './templates/ClassicText';
import ProfileIcon from './templates/ProfileIcon';
import HorizontalIconSmall from './templates/HorizontalIconSmall';
import DynamicColumnsText from './templates/DynamicColumnsText';
import AdjacentColumnsText from './templates/AdjacentColumnsText';
import ModuleLink from './ModuleLink';
import HomeMicrostoreWidget from './widges/HomeMicrostoreWidget';
import HomeAnniversaryWidget from './widges/HomeAnniversaryWidget';

const Section = (props) => {
  const accessibilityData = useSelector(selectAccessibilityData);
  const [showWidget, setShowWidget] = useState(true);

  const renderWidget = () => {
    switch (props.sectionIdentifier) {
      case ACCESSIBILITY_IDENTIFIERS.MICRO_STORE:
        return <HomeMicrostoreWidget setShowWidget={setShowWidget} />;
      case ACCESSIBILITY_IDENTIFIERS.ML_35:
        return (
          <div className="row" style={{ marginLeft: '9%', marginRight: '-3%' }}>
            {props.sectionItems.map((item, index) => renderMLDay(item, index))}
          </div>
        );
      default:
        return null;
    }
  };

  const renderMLDay = (data, index) => {
    return (
      <HomeAnniversaryWidget
        key={index}
        geography={data.geography}
        identifier={data.identifier}
        itemImageBaseUrl={data.image.base_url}
        itemImagePath={data.image.image_path}
        location={data.location}
        title={data.title}
      />
    );
  };

  const renderItem = (data, index) => {
    switch (props.sectionItemType) {
      case HOME_SECTION_TEMPLATES.CLASSIC_TEXT:
        return (
          <ClassicText
            key={index}
            itemTitle={data.title}
            itemImageBaseUrl={data.image.base_url}
            itemImagePath={data.image.image_path}
            itemId={data.id}
            itemLoadViaUrlFlag={data.content_load_type === '1'}
            itemExternalUrl={data.url}
            sectionIdentifier={props.sectionIdentifier}
            pageViews={data.page_views}
            timeToRead={data.time_to_read}
            content_load_type={data?.content_load_type}
            video_duration={data?.video_duration}
            endDateTime={data?.end_datetime}
            active={data?.active}
            question={data?.question}
            pollId={data?.poll_id}
            surveyId={data?.survey_id}
          />
        );
      case HOME_SECTION_TEMPLATES.HORIZONTAL_ICONS_SMALL:
        if (props.sectionIdentifier === '_communities') {
          return null;
        } else {
          return (
            <HorizontalIconSmall
              key={index}
              itemId={data.community_id}
              itemTitle={data.title}
              itemIconBaseUrl={data.icon_image.base_url}
              itemIconImagePath={data.icon_image.image_path}
              sectionIdentifier={props.sectionIdentifier}
              totalMembers={data.total_members}
              data={data}
            />
          );
        }

      case HOME_SECTION_TEMPLATES.PROFILE_ICONS:
        return (
          <ProfileIcon key={index} profileData={data} sectionIdentifier={props.sectionIdentifier} />
        );
      case HOME_SECTION_TEMPLATES.DYNAMIC_COLUMNS_TEXT:
        return (
          <DynamicColumnsText key={index} data={data} sectionIdentifier={props.sectionIdentifier} />
        );
      case HOME_SECTION_TEMPLATES.ADJACENT_COLUMNS_TEXT:
        return (
          <AdjacentColumnsText
            key={index}
            itemId={data.id}
            firstText={data.first_text}
            secondText={data.second_text}
            itemImageBaseUrl={data.image.base_url}
            itemImagePath={data.image.image_path}
            sectionIdentifier={props.sectionIdentifier}
          />
        );
    }
  };

  return showWidget ? (
    <div className={props.sectionIdentifier === '_communities' ? '' : 'home-section-container'}>
      <ModuleLink identifier={props.sectionIdentifier}>
        <div className="row">
          {props.sectionIdentifier !== '_communities' && (
            <div className="col-2 col-sm-1 d-flex justify-content-end">
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.HOMECREEN_LEFT_ICON_URL,
                  props.sectionIdentifier
                )}
                className="home-section-left-icon"
              />
            </div>
          )}
          <div className="col-10 col-sm-11 d-flex flex-row align-items-center">
            {props.sectionIdentifier !== '_communities' && (
              <h5 className="home-section-title">{props.sectionTitle}</h5>
            )}
            {props.sectionIdentifier !== '_communities' && (
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.HOMECREEN_RIGHT_ICON_URL,
                  props.sectionIdentifier
                )}
                className="home-section-right-icon"
              />
            )}
          </div>
        </div>
      </ModuleLink>
      {HOME_WIDGET_IDENTIFIERS.includes(props.sectionIdentifier) ? (
        renderWidget()
      ) : (
        <div className="row">
          <div className="col-0 col-sm-1" />
          <div className="col-12 col-sm-11 pl-3">
            <div
              className={
                props.sectionItemType === HOME_SECTION_TEMPLATES.DYNAMIC_COLUMNS_TEXT
                  ? 'home-section-dynamic-ctext'
                  : 'home-section-horizontal-scrollable'
              }>
              <div
                className={
                  props.sectionItemType === HOME_SECTION_TEMPLATES.DYNAMIC_COLUMNS_TEXT
                    ? 'd-flex flex-row mb-5'
                    : 'd-flex flex-row flex-nowrap'
                }>
                {props.sectionItems.map((item, index) => renderItem(item, index))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  ) : null;
};
export default Section;
