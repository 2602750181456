import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
} from '../../../common/constants/AppConstants';
import { getYoutubeVideoImageFromUrl, showToast } from '../../../utils/common';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import LikeCommentShare from './LikeCommentShare';
import { Modal } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { VscChromeClose } from 'react-icons/vsc';

const ML35VideoComponent = ({
  item,
  onPressLikeButton,
  handleLikedPeople,
  onPressCommentButton,
}) => {
  const accessibilityData = useSelector(selectAccessibilityData);
  const history = useHistory();
  const [showVideoItem, setShowVideoItem] = useState(false);

  const handleVideoItemClose = () => setShowVideoItem(false);
  const handleVideoItemShow = () => setShowVideoItem(true);

  const shareVideo = () => {
    const shareUrl = item.video_link_path;
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        showToast('Video link copied to clipboard! Paste the link to share with others.');
      })
      .catch((error) => {
        console.error('Error copying to clipboard:', error);
      });
  };

  return (
    <div className="col-sm-4 mb-3">
      <Modal
        show={showVideoItem}
        onHide={() => setShowVideoItem(false)}
        className="home-fullscreen-modal-dialog fade-scale"
        aria-labelledby="example-custom-modal-styling-title">
        <Modal.Body className="animate-bottom">
          <div
            className="home-fullscreen-modal-dialog-close-button-container"
            onClick={handleVideoItemClose}>
            <VscChromeClose size="4em" color="#fff" />
          </div>

          <ReactPlayer
            url={item.video_link_path}
            width="100%"
            height="100%"
            playing={true}
            controls={true}
            className="home-video-container"
          />
        </Modal.Body>
      </Modal>
      <div className="ml-35-video-container">
        <div onClick={handleVideoItemShow} className="ml-35-video-link">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_PLAY_VIDEO_WHITE,
              ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
            )}
            className="ml-35-play-video-icon common-cursor-pointer"
          />
        </div>

        {item.video_type === 'YT' ? (
          <img
            src={getYoutubeVideoImageFromUrl(item.video_link_path)}
            alt={item.video_title}
            className="anniversary-gallery-image mb-2"
          />
        ) : (
          <img
            src={`${item.video_thumbnail.base_url}xxhdpi/${item.video_thumbnail.image_path}`}
            alt={item.video_title}
            className="anniversary-gallery-image mb-2"
          />
        )}
      </div>
      <div className="ml-35-video-title mb-1">{item.video_title}</div>
      <div className="mb-2">
        <LikeCommentShare
          commentCount={item.comment_count}
          commentFunction={() => onPressCommentButton(item)}
          isLiked={item.liked}
          likeFunction={() => onPressLikeButton(item)}
          likePeopleFunction={() => handleLikedPeople(item)}
          likesCount={item.like_count}
          showCommentButton={true}
          showLikeButton={true}
          shareVideo={shareVideo}
          showShareButton={item.video_type === 'YT' && item.is_sharable}
        />
      </div>
    </div>
  );
};

export default ML35VideoComponent;
