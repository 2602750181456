import { createSlice } from '@reduxjs/toolkit';
import { sortBySequence } from '../utils/common';

const saveMasterdataReducer = (state, action) => {
  state.masterData = action.payload;
};

// REDUCER
const masterDataSlice = createSlice({
  name: 'masterData',
  initialState: {
    masterData: {},
  },
  reducers: {
    saveMasterdata: saveMasterdataReducer,
  },
});

// ACTIONS
const { saveMasterdata } = masterDataSlice.actions;

// SELECTOR
const selectMaxFileUploadSizeConfig = ({ masterData }) =>
  masterData.masterData.configurations._max_file_upload_size;
const selectMaxFileUploadSizeConfigProfile = ({ masterData }) =>
  masterData.masterData.configurations._max_file_upload_size_profile;
const selectBusinessDashboardUrl = ({ masterData }) => {
  return Object.keys(masterData.masterData).length
    ? masterData.masterData.configurations._distribution_sales_url
    : '';
};
const selectScheduleMeetTitleConfig = ({ masterData }) =>
  masterData.masterData.configurations._schedule_meet_default_title;

const selectSalesIncentive = ({ masterData }) => {
  return Object.keys(masterData.masterData).length
    ? masterData.masterData.configurations._sales_incentive
    : '';
};
const selectMasterData = ({ masterData }) => masterData.masterData;
const selectGeographies = ({ masterData }) => {
  return masterData.masterData.geographies ? masterData.masterData.geographies : [];
};
const selectFacilityFilters = ({ masterData }) => {
  return masterData.masterData.facility_filters ? masterData.masterData.facility_filters : [];
};
const selectRegionFilters = ({ masterData }) => {
  return masterData.masterData.regions ? masterData.masterData.regions : [];
};
const selectDealYears = ({ masterData }) => {
  return masterData.masterData.years ? masterData.masterData.years : [];
};
const selectHolidayYears = ({ masterData }) => {
  return masterData.masterData.holiday_years ? masterData.masterData.holiday_years : [];
};
const selectLayers = ({ masterData }) => masterData.masterData.layers;
const selectLevels = ({ masterData }) => masterData.masterData.levels;
const selectLocations = ({ masterData }) => {
  return masterData.masterData.locations ? masterData.masterData.locations : [];
};
const selectBloodGroupFilters = ({ masterData }) => {
  return masterData.masterData.blood_group_filters ? masterData.masterData.blood_group_filters : [];
};
const selectRequestStatusFilters = ({ masterData }) => masterData.masterData.request_status_filters;
const selectRequestApprovalFilters = ({ masterData }) =>
  masterData.masterData.request_approval_filters;
const selectYearFilters = ({ masterData }) => masterData.masterData.yearwise_filters;
const selectPolicies = ({ masterData }) => {
  return Object.keys(masterData.masterData).length ? masterData.masterData.tab_titles.policies : [];
};
const selectLoginUrl = ({ masterData }) => masterData.masterData.adfs.login_url;
const selectWelcomeData = ({ masterData }) => masterData.masterData.welcome_data;
const selectThirdPartyApps = ({ masterData }) => masterData.masterData.third_party_apps;
const selectConfigurations = ({ masterData }) => {
  return Object.keys(masterData.masterData).length ? masterData.masterData.configurations : {};
};
const selectWebBaseUrl = ({ masterData }) => {
  return Object.keys(masterData.masterData).length
    ? masterData.masterData.configurations._web_base_url
    : '';
};
const selectTermsConditionsUrl = ({ masterData }) => masterData.masterData.terms_conditions.tc_url;
const selectCompanyLogo = ({ masterData }) => masterData.masterData.company_logo;
const selectCommunities = ({ masterData }) => {
  return Object.keys(masterData.masterData).length
    ? masterData.masterData.tab_titles.communities
    : [];
};

const selectDeiAnnouncementCategories = ({ masterData }) => {
  let announcementCategories = [{ category_id: 0, title: 'All' }];
  announcementCategories = Object.keys(masterData.masterData).length
    ? [...announcementCategories, ...masterData.masterData.dei_announcement_categories]
    : [];
  return announcementCategories;
};

const selectMicrogiveCategories = ({ masterData }) => {
  let microGiveCategories = [{ category_id: 0, title: 'All' }];
  microGiveCategories = Object.keys(masterData.masterData).length
    ? [...microGiveCategories, ...masterData.masterData.microgive_categories]
    : [];
  return microGiveCategories;
};
const selectMicroGiveAbout = ({ masterData }) => masterData.masterData.static_pages[1].description;
const selectMicroGiveAboutUrl = ({ masterData }) => {
  return Object.keys(masterData.masterData).length
    ? masterData.masterData.static_pages[0].description
    : '';
};
const selectMicroGiveFaq = ({ masterData }) => {
  return Object.keys(masterData.masterData).length
    ? masterData.masterData.static_pages[2].description
    : '';
};
const selectGalleryCategories = ({ masterData }) => {
  return Object.keys(masterData.masterData).length
    ? masterData.masterData.tab_titles.gallery_categories
    : [];
};
const selectSpeech = ({ masterData }) => masterData.masterData.speech;
const selectCeoChatTerms = ({ masterData }) => masterData.masterData.ceo_chat_terms;
const selectBroadcastTitles = ({ masterData }) => {
  return Object.keys(masterData.masterData).length
    ? masterData.masterData.tab_titles.broadcasts
    : [];
};
const selectMiHRTitles = ({ masterData }) => {
  return Object.keys(masterData.masterData).length ? masterData.masterData.tab_titles.mi_hr : [];
};

const selectEventTabs = ({ masterData }) => {
  return Object.keys(masterData.masterData).length ? masterData.masterData.tab_titles.events : [];
};
const selectFacilitiesTabs = ({ masterData }) => {
  return Object.keys(masterData.masterData).length
    ? masterData.masterData.tab_titles.facilities
    : [];
};
const selectAnniversaryConfig = ({ masterData }) => {
  return Object.keys(masterData.masterData).length
    ? masterData.masterData.configurations.anniversary_config
    : [];
};
const selectContestTitles = ({ masterData }) => {
  return Object.keys(masterData.masterData).length ? masterData.masterData.tab_titles.contests : [];
};
const selectSessionDetailTabs = ({ masterData }) => masterData.masterData.tab_titles.session_detail;
const selectAskOrgTabs = ({ masterData }) => masterData.masterData.tab_titles.ask_org;
const selectCelebrationsTabs = ({ masterData }) =>
  Object.keys(masterData.masterData).length ? masterData.masterData.tab_titles.celebrations : [];
const selectIjprTabs = ({ masterData }) => masterData.masterData.tab_titles.ijpr;
const selectNewJoineesLowerLevelsTabs = ({ masterData }) => {
  return Object.keys(masterData.masterData).length
    ? masterData.masterData.tab_titles.new_joinees_lower_levels
    : [];
};
const selectNewJoineesHigherLevelsTabs = ({ masterData }) =>
  Object.keys(masterData.masterData).length
    ? masterData.masterData.tab_titles.new_joinees_higher_levels
    : [];

const selectMyProfileDetailEditTabs = ({ masterData }) =>
  Object.keys(masterData.masterData).length
    ? masterData.masterData.tab_titles.my_profile_detail_edit
    : [];

const selectMyProfileDetailTabs = ({ masterData }) => {
  let filteredArr = [];
  if (Object.keys(masterData.masterData).length) {
    filteredArr = sortBySequence(masterData.masterData.tab_titles.my_profile_detail).filter(
      (item) => item.active === true
    );
  }
  return filteredArr;
};

const selectWomensDayConfiguration = ({ masterData }) => {
  return Object.keys(masterData.masterData).length
    ? masterData.masterData.configurations._womens_day_config
    : {};
};
const selectThirdPartyWebsiteLink = ({ masterData }) => {
  return Object.keys(masterData.masterData).length
    ? masterData.masterData.configurations._microlander_url
    : '';
};
const selectCompanyWebsiteLink = ({ masterData }) => {
  return Object.keys(masterData.masterData).length
    ? masterData.masterData.configurations._company_url
    : '';
};
const selectBreakoutsTitles = ({ masterData }) => {
  return Object.keys(masterData.masterData).length
    ? masterData.masterData.tab_titles.breakouts
    : [];
};
const selectEcardsTabs = ({ masterData }) => {
  let filteredArr = [];
  if (Object.keys(masterData.masterData).length) {
    filteredArr = sortBySequence(masterData.masterData.tab_titles.eCards).filter(
      (item) => item.active === true
    );
  }
  return filteredArr;
};
const selectCheersTitles = ({ masterData }) => {
  return Object.keys(masterData.masterData).length ? masterData.masterData.tab_titles.cheers : [];
};
const selectCheersMyPoints = ({ masterData }) => {
  return Object.keys(masterData.masterData).length
    ? masterData.masterData.tab_titles.cheers_my_points
    : [];
};
const selectCheersLeadersBoard = ({ masterData }) => {
  return Object.keys(masterData.masterData).length
    ? masterData.masterData.tab_titles.cheers_leaders_board
    : [];
};
const selectMyCheers = ({ masterData }) => {
  return Object.keys(masterData.masterData).length
    ? masterData.masterData.tab_titles.my_cheers
    : [];
};
const selectCheersNominations = ({ masterData }) => {
  return Object.keys(masterData.masterData).length
    ? masterData.masterData.tab_titles.cheers_nominations
    : [];
};
const selectCitationCharLimit = ({ masterData }) =>
  masterData.masterData.configurations._citation_char_limit;

const selectMicrogive = ({ masterData }) => {
  return Object.keys(masterData.masterData).length
    ? masterData.masterData.tab_titles.microgive
    : [];
};
const selectSomTabs = ({ masterData }) => {
  return Object.keys(masterData.masterData).length ? masterData.masterData.tab_titles.som : {};
};
const selectWomensDayTabs = ({ masterData }) => {
  let filteredArr = [];
  if (Object.keys(masterData.masterData).length) {
    filteredArr = sortBySequence(masterData.masterData.tab_titles.womens_day).filter(
      (item) => item.active === true
    );
  }
  return filteredArr;
};
const selectWomensDayTabsYearWise = ({ masterData }) => {
  return Object.keys(masterData.masterData).length
    ? masterData.masterData.tab_titles.womens_day_yearwise.years
    : {};
};
const selectMicrogiveDonation = ({ masterData }) => {
  return Object.keys(masterData.masterData).length
    ? masterData.masterData.tab_titles.microgive_donation
    : [];
};
const selectMicrogiveVolunteer = ({ masterData }) =>
  masterData.masterData.tab_titles.microgive_volunteer;
const selectMicrogiveProjectDonation = ({ masterData }) =>
  masterData.masterData.tab_titles.microgive_project_donation;
const selectMicrogiveProjectVolunteer = ({ masterData }) => {
  return Object.keys(masterData.masterData).length
    ? masterData.masterData.tab_titles.microgive_project_volunteer
    : [];
};
const selectPandemicSupportTabs = ({ masterData }) => {
  return Object.keys(masterData.masterData).length
    ? masterData.masterData.tab_titles.pandemic_support
    : [];
};
const selectPollSurveysTabs = ({ masterData }) => {
  return Object.keys(masterData.masterData).length
    ? masterData.masterData.tab_titles.polls_surveys
    : [];
};

const selectGcdoTitles = ({ masterData }) => {
  let filteredArr = [];
  if (Object.keys(masterData.masterData).length) {
    filteredArr = sortBySequence(masterData.masterData.tab_titles.gcdo).filter(
      (item) => item.active === true
    );
  }
  return filteredArr;
};
const selectEsgTabsTitles = ({ masterData }) => {
  let filteredArr = [];
  if (Object.keys(masterData.masterData).length) {
    filteredArr = sortBySequence(masterData.masterData.tab_titles.esg).filter(
      (item) => item.active === true
    );
  }
  return filteredArr;
};
const selectMlfTabsTitles = ({ masterData }) => {
  let filteredArr = [];
  if (Object.keys(masterData.masterData).length) {
    filteredArr = sortBySequence(masterData.masterData.tab_titles.mlf).filter(
      (item) => item.active === true
    );
  }
  return filteredArr;
};
const selectOurCustomersTitles = ({ masterData }) => {
  return Object.keys(masterData.masterData).length
    ? masterData.masterData.tab_titles.our_customers
    : [];
};

const selectOurCustomersAllTitles = ({ masterData }) => {
  return Object.keys(masterData.masterData).length
    ? masterData.masterData.tab_titles.our_customers[0].sub_tabs
    : [];
};

const selectAnniversaryDayTabs = ({ masterData }) => {
  let filteredArr = [];
  if (Object.keys(masterData.masterData).length) {
    filteredArr = sortBySequence(masterData.masterData.anniversary_day).filter(
      (item) => item.active === true
    );
  }
  return filteredArr;
};

const selectMicareListData = ({ masterData }) => {
  let filteredArr = [];
  if (Object.keys(masterData.masterData).length) {
    filteredArr = sortBySequence(masterData.masterData.MiCare)?.filter(
      (item) => item.active === true
    );
  }
  return filteredArr;
};

const selectMicroKnowConfiguration = ({ masterData }) => {
  return Object.keys(masterData.masterData).length ? masterData.masterData.microknow : [];
};
const selectProfinityFilterWarning = ({ masterData }) =>
  masterData.masterData.configurations._profanity_filter_warning;

const selectChatbotBaseUrl = ({ masterData }) =>
  masterData.masterData.configurations._chatbot_base_url;
const selectDI = ({ masterData }) => {
  let filteredArr = [];
  if (Object.keys(masterData.masterData).length) {
    filteredArr = sortBySequence(masterData.masterData.tab_titles.di).filter(
      (item) => item.active === true
    );
  }
  return filteredArr;
};
const selectPodcastTabs = ({ masterData }) => {
  let filteredArr = [];
  if (Object.keys(masterData.masterData).length) {
    filteredArr = sortBySequence(masterData.masterData.tab_titles.podcasts).filter(
      (item) => item.active === true
    );
  }
  return filteredArr;
};
const selectDIPledge = ({ masterData }) => masterData.masterData.di_pledge_messages;
const selectGreythrTargetLink = ({ masterData }) =>
  masterData.masterData.configurations._greythr_target_link;

const selectBusinessDashboard = ({ masterData }) => {
  return Object.keys(masterData.masterData).length
    ? masterData.masterData.configurations._business_dashboard
    : '';
};
const selectCommunityPostTagPeopleLimit = ({ masterData }) =>
  masterData.masterData.tag_people_limit.community_post;
const selectMultilineTextSize = ({ masterData }) =>
  masterData.masterData.configurations._multiline_text_max_size;
const selectSingleLineTextSize = ({ masterData }) =>
  masterData.masterData.configurations._single_line_text_max_size;
const selectIjprConfigData = ({ masterData }) => masterData.masterData.configurations._ijpr_config;
const selectMicrobizTitles = ({ masterData }) => {
  let filteredArr = [];
  if (Object.keys(masterData.masterData).length) {
    filteredArr = sortBySequence(masterData.masterData.tab_titles.microbiz).filter(
      (item) => item.active === true
    );
  }
  return filteredArr;
};
const selectBtoTitles = ({ masterData }) => {
  let filteredArr = [];
  if (Object.keys(masterData.masterData).length) {
    filteredArr = sortBySequence(masterData.masterData.tab_titles.bto).filter(
      (item) => item.active === true
    );
  }
  return filteredArr;
};
const selectDIConfigData = ({ masterData }) => masterData.masterData.configurations._di_config;
const selectPodcastConfigData = ({ masterData }) =>
  masterData.masterData.configurations._podcast_config;
const selectChatEnabled = ({ masterData }) =>
  Object.keys(masterData.masterData).length
    ? masterData.masterData.configurations._chat_enabled
    : false;
const selectChatGroupMembersLimit = ({ masterData }) =>
  masterData.masterData.tag_people_limit.chat_group_members;
const selectWallpapersInfoHtmlData = ({ masterData }) =>
  masterData.masterData.configurations._wallpapers_config.info_html;
const selectWallpapersTabs = ({ masterData }) => masterData.masterData.tab_titles.wallpapers;
const selectTravelRequestTabs = ({ masterData }) => {
  let filteredArr = [];
  if (Object.keys(masterData.masterData).length) {
    filteredArr = sortBySequence(masterData.masterData.tab_titles.travel_request).filter(
      (item) => item.active === true
    );
  }
  return filteredArr;
};
const selectIjprBookmarkTabs = ({ masterData }) => {
  let filteredArr = [];
  if (Object.keys(masterData.masterData).length) {
    filteredArr = sortBySequence(masterData.masterData.tab_titles.ijpr_bookmark).filter(
      (item) => item.active === true
    );
  }
  return filteredArr;
};
const selectTravelRequestDomesticTravelJustificationData = ({ masterData }) =>
  masterData.masterData.configurations._travel_request_config
    .domestic_travel_justification_instruction;
const selectTravelRequestInternationalTravelJustificationData = ({ masterData }) =>
  masterData.masterData.configurations._travel_request_config
    .international_travel_justification_instruction;
const selectTravelRequestReviewGeneralTextData = ({ masterData }) =>
  masterData.masterData.configurations._travel_request_config.review_general_text;
const selectTravelRequestSpecialTextData = ({ masterData }) =>
  masterData.masterData.configurations._travel_request_config.special_instructions;

const selectDearMLTabs = ({ masterData }) => masterData.masterData.tab_titles.dear_ml;
const selectNewsTabs = ({ masterData }) => masterData.masterData.tab_titles.news;
const selectMicroKnowTabs = ({ masterData }) => masterData.masterData.tab_titles.microknow;
const selectDeNovoLegalTabs = ({ masterData }) =>
  masterData.masterData?.tab_titles?.micro_law || [];
const selectMLMondayTabs = ({ masterData }) => masterData.masterData.tab_titles.ml_monday;
const selectArticleFilters = ({ masterData }) => masterData.masterData.article_filters;
const selectWishesFilters = ({ masterData }) => masterData.masterData.wishes_filters;
const selectGenderList = ({ masterData }) => masterData.masterData.genders;
const selectUACDenialContent = ({ masterData }) =>
  masterData.masterData.configurations._user_access_control_config.access_denied_content;
const selectChatCharacterLimit = ({ masterData }) =>
  masterData.masterData.configurations._chat_character_limit;

const selectAssetCommentCharacterLimit = ({ masterData }) =>
  masterData.masterData.configurations._asset_character_limit;

const selectNominationList = ({ masterData }) => masterData?.masterData?.nominations_list ?? [];

const selectCheersAwardTypeStatusData = ({ masterData }) => masterData.masterData.award_type_status;
const selectwallPostConfig = ({ masterData }) =>
  masterData.masterData.configurations._wall_post_config;

const selectActivityCountText = ({ masterData }) =>
  masterData.masterData.configurations._activity_count_text;
const selectIjprConsent = ({ masterData }) =>
  masterData.masterData.configurations._ijpr_config.ijp_consent_html;
const selectMicrolandValuesContent = ({ masterData }) =>
  masterData.masterData.configurations?._microland_values_config?.content ?? '';

const selectPostSurveyResultMessage = ({ masterData }) =>
  masterData.masterData.configurations._polls_and_survey_config.survey_message_post_result || '';
const selectBeforePollResultMessage = ({ masterData }) =>
  masterData.masterData.configurations._polls_and_survey_config.poll_messag_before_result || '';
const selectBeforeSurveyEndMessage = ({ masterData }) =>
  masterData.masterData.configurations._polls_and_survey_config.survey_message_before_result || '';
const selectVariablePayTabs = ({ masterData }) => masterData.masterData.tab_titles.variable_pay;

const selectTrendingHashtags = ({ masterData }) => masterData.masterData.trending_hashtags;
const selectShowAskMLGpt = ({ masterData }) =>
  masterData.masterData.configurations._show_askmlgpt || false;
const selectChatGptConfig = ({ masterData }) =>
  masterData.masterData.configurations._chat_gpt_config || {};

const selectMichildcareTabTitles = ({ masterData }) => {
  return Object.keys(masterData.masterData).length
    ? masterData.masterData.tab_titles.michildcare
    : [];
};

const selectMicroStoreTabs = ({ masterData }) => {
  return masterData.masterData.tab_titles.micro_store || [];
};

const selectEStoreConfig = ({ masterData }) => masterData.masterData.estore;

const selectMiCareConfig = ({ masterData }) =>
  masterData.masterData.configurations?._child_care_config || [];

const selectML35Config = ({ masterData }) => masterData.masterData.configurations?._ml_35;

const masterDataSliceReducer = masterDataSlice.reducer;

export {
  masterDataSliceReducer,
  saveMasterdata,
  selectMasterData,
  selectGeographies,
  selectHolidayYears,
  selectDealYears,
  selectFacilityFilters,
  selectRegionFilters,
  selectLayers,
  selectLevels,
  selectLocations,
  selectBloodGroupFilters,
  selectRequestStatusFilters,
  selectRequestApprovalFilters,
  selectYearFilters,
  selectPolicies,
  selectLoginUrl,
  selectGalleryCategories,
  selectSpeech,
  selectWelcomeData,
  selectThirdPartyApps,
  selectConfigurations,
  selectWebBaseUrl,
  selectCeoChatTerms,
  selectTermsConditionsUrl,
  selectCompanyLogo,
  selectCommunities,
  selectBroadcastTitles,
  selectMicrogiveCategories,
  selectMicroGiveAbout,
  selectMicroGiveAboutUrl,
  selectMicroGiveFaq,
  selectAnniversaryConfig,
  selectContestTitles,
  selectWomensDayConfiguration,
  selectBreakoutsTitles,
  selectCheersTitles,
  selectCheersMyPoints,
  selectCheersLeadersBoard,
  selectMyCheers,
  selectCheersNominations,
  selectMicareListData,
  selectMicrogive,
  selectMicrogiveDonation,
  selectMicrogiveVolunteer,
  selectMicrogiveProjectDonation,
  selectMicrogiveProjectVolunteer,
  selectSessionDetailTabs,
  selectAskOrgTabs,
  selectCelebrationsTabs,
  selectIjprTabs,
  selectNewJoineesLowerLevelsTabs,
  selectNewJoineesHigherLevelsTabs,
  selectMyProfileDetailEditTabs,
  selectMyProfileDetailTabs,
  selectBusinessDashboardUrl,
  selectSomTabs,
  selectWomensDayTabs,
  selectPandemicSupportTabs,
  selectEventTabs,
  selectPollSurveysTabs,
  selectGcdoTitles,
  selectFacilitiesTabs,
  selectEcardsTabs,
  selectOurCustomersTitles,
  selectOurCustomersAllTitles,
  selectAnniversaryDayTabs,
  selectMicroKnowConfiguration,
  selectChatbotBaseUrl,
  selectDI,
  selectDIPledge,
  selectThirdPartyWebsiteLink,
  selectSalesIncentive,
  selectGreythrTargetLink,
  selectBusinessDashboard,
  selectProfinityFilterWarning,
  selectCompanyWebsiteLink,
  selectCommunityPostTagPeopleLimit,
  selectMultilineTextSize,
  selectSingleLineTextSize,
  selectIjprConfigData,
  selectMicrobizTitles,
  selectBtoTitles,
  selectDIConfigData,
  selectPodcastTabs,
  selectPodcastConfigData,
  selectChatEnabled,
  selectChatGroupMembersLimit,
  selectMaxFileUploadSizeConfig,
  selectWallpapersInfoHtmlData,
  selectWallpapersTabs,
  selectTravelRequestTabs,
  selectTravelRequestDomesticTravelJustificationData,
  selectTravelRequestInternationalTravelJustificationData,
  selectTravelRequestReviewGeneralTextData,
  selectTravelRequestSpecialTextData,
  selectIjprBookmarkTabs,
  selectScheduleMeetTitleConfig,
  selectDearMLTabs,
  selectNewsTabs,
  selectMicroKnowTabs,
  selectDeNovoLegalTabs,
  selectMLMondayTabs,
  selectArticleFilters,
  selectDeiAnnouncementCategories,
  selectNominationList,
  selectCitationCharLimit,
  selectWishesFilters,
  selectCheersAwardTypeStatusData,
  selectMiHRTitles,
  selectGenderList,
  selectUACDenialContent,
  selectChatCharacterLimit,
  selectAssetCommentCharacterLimit,
  selectwallPostConfig,
  selectActivityCountText,
  selectIjprConsent,
  selectWomensDayTabsYearWise,
  selectMaxFileUploadSizeConfigProfile,
  selectEsgTabsTitles,
  selectMlfTabsTitles,
  selectMicrolandValuesContent,
  selectPostSurveyResultMessage,
  selectBeforePollResultMessage,
  selectBeforeSurveyEndMessage,
  selectVariablePayTabs,
  selectTrendingHashtags,
  selectShowAskMLGpt,
  selectChatGptConfig,
  selectMichildcareTabTitles,
  selectMicroStoreTabs,
  selectEStoreConfig,
  selectMiCareConfig,
  selectML35Config,
};
